import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { SerializedError } from '@reduxjs/toolkit';
import { setEvent } from '../redux/modules/events';
import { isForbiddenError } from '../redux/utils';

export interface IError {
  data: any;
  status: number;
}

export default function useSnackbar() {
  const dispatch = useDispatch();

  const showError = useCallback(
    (message: string, error?: IError | undefined) => {
      if (error && error.status === 403) return;
      dispatch(
        setEvent({
          snackbar: { type: 'Error', message },
        }),
      );
    },
    [],
  );

  const showSuccess = useCallback((message: string) => {
    dispatch(
      setEvent({
        snackbar: { type: 'Success', message },
      }),
    );
  }, []);

  const showInfo = useCallback((message: string) => {
    dispatch(
      setEvent({
        snackbar: { type: 'Info', message },
      }),
    );
  }, []);

  const showForbiddenError = ({
    error,
    customDefaultMessage,
    customForbiddenMessage,
  }: {
    customForbiddenMessage?: string;
    customDefaultMessage?: string;
    error: IError | FetchBaseQueryError | SerializedError;
  }) => {
    if (!error) return;
    if (isForbiddenError(error)) {
      showError(
        customForbiddenMessage ||
          "You don't have enough permissions to do that",
      );
      return;
    }
    showError(
      customDefaultMessage || 'Something happened, your request failed',
    );
  };

  return { showError, showForbiddenError, showSuccess, showInfo };
}
