import React, {
  lazy,
  memo,
  Suspense,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Link } from 'react-router-dom';
import {
  IconButton,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
  SxProps,
} from '@mui/material';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { MapRounded, SearchRounded } from '@mui/icons-material';
import StatusFilter from '../../system/StatusFilter';
import Search from '../../../common/Search';
import Loader from '../../../common/Loader';
import { Head, Root } from './styles';
import { useAppSelector } from '../../../redux/store';
import { useGetSystemsQuery } from '../../../redux/services/systems/api';
import {
  CONTROL_STATUSES,
  MACHINE_STATUSES,
  ROUTES_PATH,
  SYSTEMS_STATUSES,
} from '../../../constants';
import { useGetSitesQuery } from '../../../redux/services/sites/api';
import { setActiveSites } from '../../../redux/modules/sites/slice';
import { retry } from '../../../utils/retry';
import { UnauthorizedComponentErrorBoundary } from '../../../common/UnauthorizedErrorBoundary';
import { isForbiddenError } from '../../../redux/utils';

const SystemMap = lazy(() => retry(() => import('../../system/map')));

interface Props {
  sx?: SxProps;
}

const MapWrapper = (props: Props) => {
  const { sx } = props;
  const statuses = SYSTEMS_STATUSES;
  const controlStatuses = CONTROL_STATUSES;
  const machineStatuses = MACHINE_STATUSES;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [status, setStatus] = useState([`${statuses[0]}`]);
  const [searchValue, setSearchValue] = useState('');
  const [searchExpanded, setSearchExpand] = useState(false);
  const {
    data: sites = [],
    error: sitesError,
    isLoading: isSitesLoading,
  } = useGetSitesQuery();

  const history = useHistory();
  const dispatch = useDispatch();
  const { activeSites, loadedActiveSites } = useAppSelector((state) => ({
    activeSites: state.activeSites.activeSites,
    loadedActiveSites: state.activeSites.loaded,
  }));

  const {
    data: systems = [],
    isLoading: isSystemsLoading,
    refetch,
  } = useGetSystemsQuery(undefined);

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (!loadedActiveSites && sites.length) {
      dispatch(
        setActiveSites({ activeSites: sites.map((it) => it.id), loaded: true }),
      );
    }
  }, [sites]);

  const loading = useMemo(() => isSystemsLoading, [isSystemsLoading]);

  const systemsLocal = useMemo(() => {
    const activeFilter = status.map((stat) => stat?.toLowerCase());
    const searchLower = searchValue?.toLowerCase();

    if (!Array.isArray(systems)) {
      return [];
    }

    let systemsSearch = systems
      ?.filter(({ site }) => activeSites.indexOf(site) > -1)
      ?.filter(({ state, display, machineState }) => {
        // has control and machine statuses filter both
        if (
          activeFilter.some((v) => controlStatuses.indexOf(v) > -1) &&
          activeFilter.some((v) => machineStatuses.indexOf(v) > -1)
        ) {
          if (
            activeFilter.indexOf(machineState?.toLowerCase()) > -1 &&
            activeFilter.indexOf(display?.toLowerCase()) > -1
          ) {
            return true;
          }
          if (
            activeFilter.indexOf(machineState?.toLowerCase()) > -1 &&
            activeFilter.indexOf('running') > -1 &&
            state > 0
          ) {
            return true;
          }
          if (
            activeFilter.indexOf(machineState?.toLowerCase()) > -1 &&
            activeFilter.indexOf('unknown') > -1 &&
            display === undefined
          ) {
            return true;
          }
        }

        // has any of control and machine statuses filter
        if (
          activeFilter.every((v) => controlStatuses.indexOf(v) > -1) ||
          activeFilter.every((v) => machineStatuses.indexOf(v) > -1)
        ) {
          if (activeFilter.indexOf(machineState?.toLowerCase()) > -1) {
            return true;
          }
          if (activeFilter.indexOf(display?.toLowerCase()) > -1) {
            return true;
          }
          if (activeFilter.indexOf('running') > -1 && state > 0) {
            return true;
          }
          if (activeFilter.indexOf('unknown') > -1 && display === undefined) {
            return true;
          }
        }

        if (activeFilter.indexOf('all') > -1) {
          return true;
        }
        return false;
      });
    if (searchLower)
      systemsSearch = systemsSearch.filter(
        ({ name }) => name?.toLowerCase().indexOf(searchLower) > -1,
      );

    if (activeFilter.indexOf('all') > -1)
      return systemsSearch.map(({ id }) => id);

    return systemsSearch.map(({ id }) => id);
  }, [systems, status, searchValue, activeSites]);

  const handleSearchInput = (event) => {
    setSearchValue(event.target.value);
  };

  const handleStatusChange = (event) => {
    const val = event?.target.value;

    if (val.length === 0) {
      setStatus([`${statuses[0]}`]);
      return;
    }
    if (val[val.length - 1]?.toLowerCase().startsWith('all')) {
      setStatus([`${statuses[0]}`]);
      return;
    }
    setStatus(
      val.filter((v) => v?.toLowerCase() !== statuses[0]?.toLowerCase()),
    );
  };

  useEffect(() => {
    if (status.length === 0) {
      setStatus([`${statuses[0]}`]);
    }
  }, [status]);

  const handleClearSearch = () => {
    setSearchValue('');
    setSearchExpand(false);
  };

  const expandSearch = () => {
    setSearchExpand(true);
  };

  return (
    <Root sx={sx}>
      {isSitesLoading ? (
        <Loader position="relative" x={50} y={50} nonetransform="true" />
      ) : (
        <UnauthorizedComponentErrorBoundary
          isError={isForbiddenError(sitesError)}
        >
          <Head>
            {!searchExpanded && (
              <>
                <Typography variant="subtitle1" sx={{ mr: 'auto' }}>
                  Map
                </Typography>
                <StatusFilter
                  value={status}
                  onChange={handleStatusChange}
                  statuses={statuses}
                  sx={{ maxWidth: '160px', mx: 2 }}
                  id="DashboardMap-status_filter"
                />
              </>
            )}
            {(searchExpanded || !isMobile) && (
              <Search
                placeholder="Search"
                value={searchValue}
                onSearch={handleSearchInput}
                onCancel={handleClearSearch}
                defaultExpanded={!isMobile}
                sx={{ maxWidth: isMobile ? '100%' : '220px' }}
                id="DashboardMap-search"
              />
            )}
            {!searchExpanded && isMobile && (
              <IconButton
                id="DashboardMap-Search-button"
                onClick={expandSearch}
              >
                <SearchRounded />
              </IconButton>
            )}
            <Tooltip title="Expand map view">
              <IconButton
                sx={{ ml: 1, mr: -1 }}
                component={Link}
                to={`${ROUTES_PATH.SYSTEMS}/map`}
                id="DashboardMap-expand_button"
              >
                <MapRounded />
              </IconButton>
            </Tooltip>
          </Head>
          {loading ? (
            <Loader position="relative" x={50} y={50} nonetransform="true" />
          ) : (
            <Suspense
              fallback={
                <Loader
                  position="relative"
                  x={50}
                  y={50}
                  nonetransform="true"
                />
              }
            >
              <SystemMap systemsLocal={systemsLocal} />
            </Suspense>
          )}
        </UnauthorizedComponentErrorBoundary>
      )}
    </Root>
  );
};

MapWrapper.defaultProps = {
  sx: {},
};

export default memo(MapWrapper);
