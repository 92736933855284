import { v4 } from 'uuid';
import React, {
  useContext,
  useMemo,
  useState,
  useEffect,
  memo,
  useCallback,
} from 'react';
import { Box, styled, useTheme } from '@mui/material';
import { Handle, Position, useUpdateNodeInternals } from 'react-flow-renderer';
import { Context } from '../../CreateProjectDiagram/context';
import {
  useGetVisualQuery,
  useGetVisualAssetQuery,
} from '../../../../redux/services/visuals/api';
import Progress from './Progress';
import { apiBaseUrlV1 } from '../../../../env';
import PlaceholderSVGComponent from './Placeholder';
import { useGetPortsQuery } from '../../../../redux/services/ports/api';
import { useGetSystemByIdQuery } from '../../../../redux/services/systems/api';
import { useGetAttributeByAggregateQuery } from '../../../../redux/services/attributes';
import { WarningIcon } from '../../../../icons';
import { IComponent } from '../../../../typescript/interfaces/component.interface';
import { useGetInstrumentsByAggregateQuery } from '../../../../redux/services/instruments/api';
import { OldComponentData } from '../../../../typescript/interfaces/oldComponentData.interface';
import useSnackbar from '../../../../hooks/useSnackbar';
import WarningDiagramComponent from './WarningDiagramComponent';
import { useGetProjectByIdQuery } from '../../../../redux/services/ensProjects/api';
import { colorToHex, hexToRGBA } from '../../../../utils/stringUtils';

const BASE_URL = apiBaseUrlV1('structure/v1/visuals');

const IndicatorWrap = styled(Box)({
  position: 'absolute',
  zIndex: 9999,
  display: 'flex',
  flexDirection: 'column-reverse',
  justifyContent: 'center',
  alignItems: 'center',
  width: '10px',
  height: '10px',
  top: '50%',
  left: '50%',
  '& svg': {},
});

const NodeWrap = styled(Box)({
  '&.selected': {
    border: '1px solid #4C9FC8',
  },
});

const Label = styled('span')({
  position: 'absolute',
  zIndex: 99,
  textAlign: 'center',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '1px',
  height: '1px',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  '&.M': {
    fontSize: '1.25rem',
  },
  '&.S': {
    fontSize: '0.75rem',
  },
  '&.L': {
    fontSize: '2.5rem',
  },
  '&.XL': {
    fontSize: '3.5rem',
  },
  '& span': {
    position: 'absolute',
    display: 'block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    padding: '0 2px',
    borderRadius: '2px',
    lineHeight: '1.5',
    transform: 'translate(-50%, -50%)',
  },
  '& svg': {
    width: '80%',
  },
});

const SVGWrap = styled(Box)({
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

interface FitProps {
  children: string | number;
  position: { x: number; y: number };
  textColor: string;
  backgroundColor: string;
  idTitle: string;
}

// eslint-disable-next-line react/display-name
const TextFit = memo((props: FitProps) => {
  const { children, position, textColor, backgroundColor, idTitle } = props;
  const theme = useTheme();

  const color =
    textColor === 'default' || textColor === undefined
      ? theme.palette.secondary.contrastText
      : textColor;
  const bgColor =
    backgroundColor === 'default' || backgroundColor === undefined
      ? theme.palette.primary.main
      : backgroundColor;

  return (
    <Box
      component="span"
      id={`SystemDiagram-NodeLabel-${idTitle}`}
      sx={{
        top: position.y,
        left: position.x,
        textAnchor: 'middle',
        dominantBaseline: 'middle',
        color,
        backgroundColor: bgColor,
      }}
    >
      {children}
    </Box>
  );
});

interface Props {
  componentName: string;
  type: string;
  id: string;
  selected: boolean;
  data?: OldComponentData;
  systemId: string;
}

interface SVGElementProps {
  SVGToRender: string;
  style: React.CSSProperties;
}

export type VisualViewControllerParams = {
  background_color: string | null;
  background_color_default: string | null;
  background_opacity: string | null;
  background_opacity_default: string | null;
  border_color: string | null;
  border_color_default: string | null;
  border_style: string | null;
  border_style_default: string | null;
};

type FlairProps = Partial<VisualViewControllerParams> & {
  type: string;
  value: string | number;
  position: { x: number; y: number };
  prefix?: string;
  suffix?: string;
  orientation?: string;
  propertyName?: any;
  maximum?: number;
  size?: string;
  textColor: string;
  backgroundColor: string;
  trueColor?: string;
  falseColor?: string;
  textSize?: string;
  label?: string;
  anchor?: string;
};

interface ComponentFlairProps {
  flair: FlairProps;
  currentComponent: IComponent;
  systemId: string;
  handleDiagram: (val: string) => void;
  handleNode: (val: VisualViewControllerParams) => void;
}

interface PortProps {
  id: string;
  type: string;
  position: Position;
  style;
}

interface BarProps {
  value: string | number;
  position: { x: number; y: number };
  orientation?: string;
  maximum?: number;
}

interface LabelProps {
  value: string | number;
  position: { x: number; y: number };
  prefix?: string;
  suffix?: string;
  size?: string;
  textColor: string;
  backgroundColor: string;
}

interface FlairBooleanIndicatorProps {
  position: { x: number; y: number };
  color: string;
}
interface VisualViewControllerProps {
  position: { x: number; y: number };
  value: VisualViewControllerParams;
  handleDiagram: (value: string) => void;
  handleNode: (value: VisualViewControllerParams) => void;
}

const SVGElement = (props: SVGElementProps) => {
  const { SVGToRender, style } = props;

  if (!SVGToRender) return null;
  return (
    <SVGWrap
      style={style}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: SVGToRender }}
    />
  );
};

const FlairBooleanIndicator = (props: FlairBooleanIndicatorProps) => {
  const { position, color } = props;

  return (
    <IndicatorWrap>
      <svg
        width="15"
        height="30"
        style={{
          position: 'relative',
          top: position.y,
          left: position.x,
          zIndex: 9999,
        }}
      >
        <rect width="10" height="10" fill={color} />
      </svg>
    </IndicatorWrap>
  );
};

const VisualViewController = (props: VisualViewControllerProps) => {
  const { value, handleNode } = props;

  useEffect(() => {
    handleNode(value);
  }, [value]);

  return null;
};

const FlairBar = (props: BarProps) => {
  const { value, position, orientation, maximum } = props;

  if (orientation?.toLowerCase() === 'vertical')
    return (
      <Progress
        progress={parseInt(`${value}`, 10)}
        maxValue={maximum}
        position={position}
        hideValues
      />
    );
  return <p>Horizontal bar</p>;
};
const FlairLabel = (props: LabelProps) => {
  const { value, position, prefix, suffix, size, textColor, backgroundColor } =
    props;

  return (
    <Label className={size ?? 'M'}>
      <TextFit
        position={position}
        textColor={textColor}
        backgroundColor={backgroundColor}
        idTitle={`${value}`}
      >{`${prefix ?? ''} ${value?.toString() || value} ${
        suffix ?? ''
      }`}</TextFit>
    </Label>
  );
};

const mixedFlairsValue = (data, label, f) =>
  data?.label?.props?.value
    ? data?.label?.props?.value[f.label]
    : label?.props?.name || label?.props?.id || '';

const FlairsMemo = ({
  flair,
  currentComponent,
  systemId,
  handleDiagram,
  handleNode,
}: ComponentFlairProps) => {
  const {
    type,
    value,
    position,
    prefix,
    suffix,
    orientation,
    propertyName,
    maximum,
    size,
    textColor,
    backgroundColor,
    trueColor,
    falseColor,
  } = useMemo(() => flair, [JSON.stringify(flair)]);

  const { data: instrument } = useGetInstrumentsByAggregateQuery(systemId, {
    selectFromResult: ({ data }) => ({
      data: data?.find(
        (instr) => instr.parentAggregate === currentComponent?.id,
      ),
    }),
    skip: !currentComponent?.id,
  });
  const { data: attributesData } = useGetAttributeByAggregateQuery(systemId, {
    skip: !instrument || !currentComponent?.id,
  });

  const componentValMerged = useMemo(() => {
    if (!currentComponent?.value) return undefined;
    const val = Object.values(currentComponent?.value)?.reduce(
      (accumulator, localValue) => ({ ...accumulator, ...localValue }),
      {},
    );
    return val;
  }, [currentComponent]);

  const currentValue = useMemo(() => {
    if (type === 'visualViewController') {
      /* eslint-disable camelcase */
      const property = propertyName?.[propertyName?.length - 1]?.value;

      const val =
        componentValMerged ||
        attributesData?.find(({ name }) => name === property)?.default ||
        flair;

      const valReassigned = Object.fromEntries(
        Object.entries(val).map(([prop, v]) => [
          prop,
          v === 'None' || v === null ? undefined : v,
        ]),
      );

      const {
        background_color,
        background_color_default,
        background_opacity,
        background_opacity_default,
        border_color,
        border_color_default,
        border_style,
        border_style_default,
      } = valReassigned;
      return {
        background_color,
        background_color_default,
        background_opacity,
        background_opacity_default,
        border_color,
        border_color_default,
        border_style,
        border_style_default,
      };
      /* eslint-enable camelcase */
    }
    if (componentValMerged && propertyName?.length > 0) {
      const property = propertyName[propertyName.length - 1].value;
      if (typeof componentValMerged?.[property] === 'boolean') {
        return componentValMerged[property];
      }
      if (!Number.isNaN(parseFloat(componentValMerged?.[property]))) {
        return Number(componentValMerged?.[property]).toFixed(2);
      }
      if (componentValMerged?.[property] !== undefined) {
        return componentValMerged[property];
      }
    }

    return value;
  }, [
    componentValMerged,
    JSON.stringify(attributesData),
    JSON.stringify(currentComponent),
  ]);

  if (type === 'bar')
    return (
      <FlairBar
        value={currentValue}
        position={position}
        key={`${type}-${position.x}-${position.y}`}
        orientation={orientation}
        maximum={maximum}
      />
    );
  if (type === 'booleanIndicator')
    return (
      <FlairBooleanIndicator
        position={position}
        key={`${type}-${position.x}-${position.y}`}
        color={currentValue ? trueColor : falseColor}
      />
    );
  if (type === 'visualViewController') {
    return (
      <VisualViewController
        position={position}
        key={`${type}-${position.x}-${position.y}`}
        value={currentValue}
        handleDiagram={handleDiagram}
        handleNode={handleNode}
      />
    );
  }
  return (
    <FlairLabel
      value={currentValue}
      position={position}
      key={`${type}-${position.x}-${position.y}`}
      prefix={prefix}
      suffix={suffix}
      size={size}
      textColor={textColor}
      backgroundColor={backgroundColor}
    />
  );
};

const Flairs = memo(FlairsMemo);

const Ports = ({ id, type, position, style }: PortProps) => (
  <Handle
    className="point"
    //@ts-ignore
    type={type}
    position={position}
    id={id || `${type}-${JSON.stringify(position)}-${JSON.stringify(style)}`}
    style={style}
  />
);

const portConvert = (name, properties, id?) => {
  if (properties?.type !== 'portAnchor') return undefined;
  let transform = '';
  let portType;
  if (name.startsWith('feed'))
    portType = name.startsWith('feed') ? 'target' : 'source';
  if (properties?.flow)
    portType = properties.flow.toLowerCase() === 'outlet' ? 'source' : 'target';

  if (!portType) portType = 'target';

  let position = '';

  position = properties?.heading?.toLowerCase() || Position.Top;
  transform = `translate(${properties?.placement?.x || 0}px, ${
    properties?.placement?.y || 0
  }px)`;

  return {
    id: id || name,
    type: portType,
    position,
    style: { transform },
  };
};

const defaultPorts = [
  {
    id: 'top-right-handle',
    type: 'source',
    position: Position.Right,
    style: { top: 14, right: 4, transform: 'none' },
  },
  {
    id: 'top-left-handle',
    type: 'target',
    position: Position.Right,
    style: { top: 14, left: 4, transform: 'none' },
  },
];

const defaultBoxSize = { width: 72, height: 72 };

const setFlairsValueHandler = (
  data,
  key,
  flairs,
  id,
  localFlairsRender,
  visualFlairs,
  bgColorDefault,
  textColorDefault,
) => {
  const val = data?.label?.props?.value ? data?.label?.props?.value[key] : '';

  const flair: FlairProps = {
    label: key,
    type: flairs[key].type,
    value: key === 'nameLabel' ? id : val,
    position: {
      x: flairs[key].placement.x,
      y: flairs[key].placement.y,
    },
    backgroundColor: flairs[key].backgroundColor || bgColorDefault,
    textColor: flairs[key].textColor || textColorDefault,
  };
  if (flair.type === 'bar') {
    flair.orientation = flairs[key].orientation.toLowerCase() || '';
    flair.maximum = flairs[key].maximum;
  }
  if (flair.type === 'label' && key !== 'nameLabel') {
    flair.suffix = flairs[key].suffix;
    flair.textSize = flairs[key].textSize;
  }
  if (flair.type === 'booleanIndicator') {
    flair.trueColor = flairs[key].trueColor;
    flair.falseColor = flairs[key].falseColor;
  }

  if (!localFlairsRender.find((lfr) => lfr?.label === flair?.label))
    visualFlairs.push(flair);
};

const setVisualPortsHandler = (localPortsData, key, flairs, visualPorts) => {
  const portsData =
    localPortsData?.render !== undefined && localPortsData?.render !== null
      ? Object.entries(localPortsData?.render).filter(
          // @ts-ignore
          ([_, portValue]) => portValue?.anchor === key,
        )[0]
      : undefined;

  let name = '';

  if (portsData?.length > 0) [name] = portsData;

  let position = '';
  let transform = '';
  const portType =
    key.toLocaleLowerCase().startsWith('input') ||
    key.toLocaleLowerCase().startsWith('inlet')
      ? 'target'
      : 'source';
  if (flairs[key].heading) {
    position = flairs[key].heading.toLowerCase();
  }
  if (position === '')
    position = flairs[key].placement.x >= 0 ? Position.Right : Position.Left;
  transform = `translate(${flairs[key].placement.x}px, ${flairs[key].placement.y}px)`;

  visualPorts.push({
    id: name || key,
    type: portType,
    position,
    style: { transform },
  });
};

const setBoxSizeHandler = (dataSvgLocal, renderProperties, setBoxSize) => {
  const regSvg = dataSvgLocal.match(/viewBox="(?<viewBox>.*)"/);
  if (regSvg?.groups?.viewBox) {
    const [, , widthSvg, heightSvg] = regSvg.groups.viewBox.split(' ');
    if (widthSvg && heightSvg) {
      const ratio =
        renderProperties !== undefined
          ? +renderProperties.size?.height / +renderProperties.size?.width
          : +heightSvg / +widthSvg;

      setBoxSize({
        width: +renderProperties?.size?.width || 72,
        height: +renderProperties?.size?.height || +(72 * ratio)?.toFixed(2),
      });
    }
  }
};

const sortingDataSvg = (a, b) => {
  if (a > b) return -1;
  if (a < b) return -1;
  return 0;
};

interface NodeState {
  backgroundColor?: string;
  borderStyle?: string;
  borderColor?: string;
}

const defaultNodeState: NodeState = {};

const DiagramComponent = (props: Props) => {
  const { id, data, selected } = props;
  const { label } = data;
  const { width: sizeWidth, height: sizeHeight } = data?.size || {};
  const defaultStateBoxSize = {
    width: sizeWidth || defaultBoxSize.width,
    height: sizeHeight || defaultBoxSize.height,
  };
  const theme = useTheme();
  let { systemId } = props;

  if (!systemId) systemId = label?.props?.systemId;

  const { nodesDraggable } = useContext(Context) || {};

  const [componentKey, setKey] = useState(Date.now());
  const {
    handleNodeLoading,
    handleDiagram,
    isLoading: isLoadingGlobal,
  } = useMemo(() => data, [data]);
  const [isLoading, setIsLoading] = useState(isLoadingGlobal);
  const [nodeState, setNodeState] = useState<NodeState>(defaultNodeState);

  const { data: portsSelector } = useGetPortsQuery(systemId, {
    skip: !systemId,
  });

  const updateNodeInternals = useUpdateNodeInternals();

  const selectedPorts = useMemo(
    () =>
      portsSelector?.filter(
        ({ parentAggregate }) => parentAggregate === label?.props?.id,
      ) || [],
    [label, portsSelector],
  );

  const localPortsData = useMemo(
    () =>
      label?.props?.data?.renderProperties?.ports ||
      label?.props?.visual?.flairOverrides ||
      data?.renderProperties?.ports ||
      {},
    [label, JSON.stringify(data)],
  );

  const localPortsRender = useMemo(() => {
    if (!localPortsData) return [];
    if (localPortsData?.render)
      if (Object.keys(localPortsData?.render)?.length > 0)
        return Object.entries(localPortsData.render).map(([key, value]) => {
          const renderData =
            localPortsData?.visual !== undefined &&
            localPortsData?.visual !== null
              ? Object.entries(localPortsData.visual).filter(
                  // @ts-ignore
                  ([name, _]) => name === value?.anchor,
                )
              : undefined;

          if (renderData?.length > 0) {
            const result = renderData
              .map(([portName, portRenderProperties]) =>
                portConvert(portName, portRenderProperties),
              )
              .flat(2)
              .filter((v) => v);

            return result[0];
          }
          return [];
        });

    if (localPortsData?.visual) {
      if (Object.keys(localPortsData?.visual)?.length > 0) {
        const parsedPorts = Object.entries(localPortsData?.visual).map(
          ([name, value]) => portConvert(name, value),
        );

        return parsedPorts;
      }
    }

    if (localPortsData && selectedPorts.length > 0) {
      return selectedPorts.map((localSelectedPort) => {
        const renderData = Object.entries(localPortsData)
          .filter(([name, _]) => name === localSelectedPort?.anchor)
          .map(([key, renderProperties]) =>
            portConvert(key, renderProperties, localSelectedPort.id),
          );
        return renderData;
      });
    }
    return [];
  }, [localPortsData, selectedPorts]);

  const localFlairsData = useMemo(
    () =>
      label?.props?.data?.renderProperties?.flairs?.render ||
      label?.props?.visual?.flairOverrides ||
      data?.renderProperties?.flairs?.render ||
      {},
    [label, JSON.stringify(data)],
  );

  const ifFlairValueUndefined = (value, flairValue) =>
    value?.default && flairValue === undefined;
  const ifBackgroundUndefined = (value) =>
    value?.backgroundColor || theme.palette.primary.main;
  const ifColorUndefined = (value) =>
    value?.textColor || theme.palette.secondary.contrastText;

  const getFlairValue = useCallback(
    (value, key) => {
      if (value === null) return { label: key, value: null };

      if (!value?.type || value?.type === 'portAnchor') {
        return undefined;
      }

      let flairValue;
      if (typeof value?.value === 'string') flairValue = value?.value;

      if (data?.label?.props?.value) {
        if (data?.label?.props?.value[key]) {
          flairValue = data?.label?.props?.value[key];
        }
      }

      if (!Number.isNaN(value?.default * 1) && flairValue === undefined)
        flairValue = value?.default;

      if (ifFlairValueUndefined(value, flairValue)) flairValue = value?.default;

      if (flairValue === undefined) flairValue = key;

      const flair: FlairProps = {
        ...value,
        label: key,
        type: value?.type,
        value: flairValue,
        position: {
          x: value?.placement?.x,
          y: value?.placement?.y,
        },
        backgroundColor: ifBackgroundUndefined(value),
        textColor: ifColorUndefined(value),
      };

      if (flair.type === 'bar') {
        flair.orientation = (value?.orientation || 'vertical').toLowerCase();
        flair.maximum = value?.maximum;
      }
      if (key !== 'nameLabel') {
        flair.suffix = value?.suffix;
        flair.size = value?.textSize;
      }

      if (flair.type === 'booleanIndicator') {
        flair.trueColor = value?.trueColor;
        flair.falseColor = value?.falseColor;
      }

      return flair;
    },
    [data],
  );

  const localFlairsRender = useMemo(
    () =>
      localFlairsData
        ? Object.entries(localFlairsData)
            .map(([key, value]) => getFlairValue(value, key))
            .filter((v) => v !== undefined)
        : [],
    [localFlairsData],
  );

  const renderProperties = useMemo(() => {
    let rProperties;
    if (typeof label !== 'string') {
      rProperties = label?.props?.data?.renderProperties;
    } else {
      rProperties = props?.data?.renderProperties;
    }

    if (!rProperties) {
      rProperties = data?.renderProperties;
    }

    return rProperties;
  }, [props]);

  const idFromLink = useMemo(() => {
    if (renderProperties?.link?.componentLink?.startsWith(BASE_URL)) {
      const url = new URL(renderProperties?.link?.componentLink);
      const arr = url.pathname.split('/');

      return arr[arr.length - 1];
    }
    if (renderProperties?.link?.componentLink?.startsWith('<svg')) {
      return renderProperties?.link?.componentLink;
    }
    return undefined;
  }, [renderProperties]);

  const { data: dataVisual, error } = useGetVisualQuery(idFromLink, {
    skip: idFromLink?.startsWith('<svg') || !idFromLink,
  });
  const { data: dataSvg, error: svgError } = useGetVisualAssetQuery(
    {
      id: idFromLink,
      version: renderProperties?.link?.version || 0,
    },
    { skip: idFromLink?.startsWith('<svg') },
  );

  const { data: systemSelector } = useGetSystemByIdQuery(systemId, {
    skip: systemId?.startsWith('PROJ') || !systemId,
  });
  const { data: projectSelector } = useGetProjectByIdQuery(systemId, {
    skip: systemId?.startsWith('SYST') || !systemId,
  });

  const system = useMemo(() => {
    if (projectSelector || systemSelector) {
      return projectSelector || systemSelector;
    }
    return undefined;
  }, [systemSelector, projectSelector]);

  const defaultVisualsDataForSystem =
    system?.visuals?.[label?.props?.visual?.name];
  const defaultFlairsFromSystem = defaultVisualsDataForSystem?.flairs;

  const componentValue = useMemo(() => {
    if (!system?.components) return undefined;
    return system?.components?.find((c) => c.id === id);
  }, [system]);

  const [Element, setElement] = useState<any>(undefined);
  const [flairs, setFlairs] = useState([]);
  const [ports, setPorts] = useState([]);
  const [boxSize, setBoxSize] = useState(defaultStateBoxSize);
  const { showError } = useSnackbar();
  useEffect(() => {
    if (!idFromLink) {
      fetch(
        `data:image/svg+xml;base64,PHN2ZyBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLW1pdGVybGltaXQ9IjIiIHZpZXdCb3g9IjAgMCAyNCAyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJtMjEgMy45OTljMC0uNDc4LS4zNzktMS0xLTFoLTE2Yy0uNjIgMC0xIC41MTktMSAxdjE2YzAgLjYyMS41MiAxIDEgMWgxNmMuNDc4IDAgMS0uMzc5IDEtMXptLTEuNSAxNS41aC0xNXYtMTFoMTV6bTAtMTIuNWgtMTV2LTIuNWgxNXoiIGZpbGwtcnVsZT0ibm9uemVybyIvPjwvc3ZnPg==`,
      )
        .then((res) => res.text())
        .then((res) => {
          if (handleNodeLoading) handleNodeLoading(id);
          setIsLoading(false);
          setElement(res);
        })
        .catch((errInner) => {
          console.error('Error trying to fetch svg: ', { errInner });
          setElement(undefined);
        });
    }
  }, [idFromLink]);

  useEffect(() => {
    if ((error || svgError) && !Element) {
      setElement(<WarningIcon />);
      if (!defaultFlairsFromSystem) showError('Failed to load visuals');
      if (handleNodeLoading) handleNodeLoading(id);
    }
  }, [error, svgError, defaultFlairsFromSystem]);

  useEffect(() => {
    if (renderProperties?.size) {
      const { width, height } = renderProperties.size;

      setBoxSize(() => ({
        width,
        height,
      }));
    }
  }, [JSON.stringify(renderProperties?.size)]);

  const isInvalidSize = (svgHTML) =>
    (svgHTML && renderProperties?.size === undefined) ||
    renderProperties?.size === null;

  useEffect(() => {
    if (idFromLink?.startsWith('<svg')) {
      return;
    }

    if (!dataSvg) {
      setFlairs([]);
      setPorts(defaultPorts);
      setBoxSize(componentValue?.visual?.size || defaultStateBoxSize);
      return;
    }

    setIsLoading(false);
    if (handleNodeLoading) handleNodeLoading(id);
    if (dataSvg.startsWith('<svg')) {
      const regEx = /url\(#(?<id>.+?)\)/gm;

      const matches = [
        ...new Set([...dataSvg.matchAll(regEx)].map((match) => match[1])),
      ].sort((a, b) => sortingDataSvg(a, b));

      let dataSvgReplaced = dataSvg;

      matches.forEach((match) => {
        const uuid = v4().replace(/-/gm, '');
        const regExpId = new RegExp(`"${match}"`, 'gm');
        const regExpUrl = new RegExp(`#${match}`, 'gm');
        dataSvgReplaced = dataSvgReplaced.replace(regExpId, `"${uuid}"`);
        dataSvgReplaced = dataSvgReplaced.replace(regExpUrl, `#${uuid}`);
      });

      const dataSvgLocal = dataSvgReplaced.replace(
        /fill="none"/gm,
        'fill="transparent"',
      );

      setElement(dataSvgLocal);
      const svgHTML = dataSvgLocal.match(/<svg .*$/gm);
      if (isInvalidSize(svgHTML)) {
        const searchIn = svgHTML[0];
        try {
          const widthSearch = searchIn?.match(/width="(?<width>.*?)"/);
          const heightSearch = searchIn?.match(/height="(?<height>.*?)"/);

          if (!widthSearch?.groups?.width || !heightSearch?.groups?.height) {
            setBoxSize({
              width: defaultStateBoxSize.width,
              height: defaultStateBoxSize.height,
            });
          } else {
            const widthFound = widthSearch?.groups?.width;
            const heightFound = heightSearch?.groups?.height;

            setBoxSize({ width: +widthFound, height: +heightFound });
          }
        } catch (e) {
          console.warn(e);
        }
      }
      if (!isInvalidSize(svgHTML))
        setBoxSizeHandler(dataSvgLocal, renderProperties, setBoxSize);
    }

    const localFlairs = dataVisual?.flairs || defaultFlairsFromSystem;

    if (localFlairs) {
      const visualFlairs = [];
      const visualPorts = [];

      Object.keys(localFlairs).forEach((key) => {
        if (localFlairs[key].type === 'portAnchor') {
          setVisualPortsHandler(localPortsData, key, localFlairs, visualPorts);
        } else {
          setFlairsValueHandler(
            data,
            key,
            localFlairs,
            id,
            localFlairsRender,
            visualFlairs,
            theme.palette.primary.main,
            theme.palette.secondary.contrastText,
          );
        }
      });

      setFlairs(visualFlairs);
      setPorts(visualPorts.flat(2));
    }
  }, [
    renderProperties,
    componentValue,
    dataVisual,
    defaultFlairsFromSystem,
    dataSvg,
    theme,
  ]);

  useEffect(() => {
    if (idFromLink?.startsWith('<svg')) {
      setElement(idFromLink);
      setIsLoading(false);
    }
  }, [idFromLink]);

  const inputs = useMemo(
    () => label?.props?.visual?.inputs || undefined,
    [label],
  );

  const mixedFlairs = useMemo(
    // @ts-ignore
    (prev) => {
      const newState = [...flairs, ...localFlairsRender]
        .map((f) => {
          let matchedString;
          try {
            const [_, stream, instrument, attributeName, attributeValue] =
              inputs[f.label].split('.');
            matchedString = [
              { group: 'stream', value: stream },
              { group: 'instrument', value: instrument || stream },
              {
                group: 'attributeName',
                value: attributeName || instrument || stream,
              },
              {
                group: 'attributeValue',
                value: attributeValue || attributeName || instrument || stream,
              },
            ];
          } catch {
            matchedString = undefined;
          }

          if (f.value === null) return undefined;
          if (f.value === label?.props?.id) {
            return {
              ...f,
              value: mixedFlairsValue(data, label, f),
              key: `${f.label}${Date.now()}`,
              propertyName: matchedString
                ? [{ label: f.label }, ...matchedString]
                : undefined,
            };
          }
          return {
            ...f,
            key: `${f.label}${Date.now()}`,
            propertyName: matchedString
              ? [{ label: f.label }, ...matchedString]
              : undefined,
          };
        })
        .filter((v) => v);
      if (JSON.stringify(prev) !== JSON.stringify(newState)) {
        return newState;
      }
      return prev;
    },
    [flairs, localFlairsRender, inputs],
  );
  const mixedPorts = useMemo(
    () =>
      [...localPortsRender.flat(2), ...ports.flat(2)]
        .map((port) => {
          const [isExisting] = selectedPorts.filter(
            // @ts-ignore
            ({ anchor }) => port.id === anchor,
          );
          if (isExisting) return { ...port, id: isExisting.id };
          return port;
        })
        .filter(
          (value, index, self) =>
            self.findIndex((v) => v?.id === value?.id) === index,
        ),
    [ports, localPortsRender, selectedPorts],
  );

  const handleNode = (val: VisualViewControllerParams) => {
    /* eslint-disable camelcase */
    if (!val) return;

    try {
      const {
        background_color,
        background_color_default,
        background_opacity,
        background_opacity_default,
        border_style,
        border_style_default,
        border_color,
        border_color_default,
      } = val;

      const bgColor =
        background_color || background_color_default || 'transparent';
      const bgOpacity =
        Number(background_opacity) === +background_opacity
          ? background_opacity
          : background_opacity_default;

      const backgroundColor = hexToRGBA(colorToHex(bgColor), bgOpacity);

      const borderColor = border_color || border_color_default;
      const borderStyle = border_style || border_style_default;

      if (
        nodeState.borderColor === borderColor &&
        nodeState.borderStyle === borderStyle &&
        nodeState.backgroundColor === backgroundColor
      )
        return;

      setNodeState({
        borderColor,
        borderStyle,
        backgroundColor,
      });
    } catch (_) {
      console.warn(`visual view controller value is not parsable: ${val}`);
    }
    /* eslint-enable camelcase */
  };

  useEffect(() => {
    updateNodeInternals(label?.props?.id || label);
    setKey(Date.now());
  }, [mixedFlairs, mixedPorts]);

  if (isLoading) {
    return (
      <PlaceholderSVGComponent
        height={renderProperties?.size?.height || boxSize.height}
        width={renderProperties?.size?.width || boxSize.width}
      />
    );
  }

  return (
    <NodeWrap
      sx={{
        position: 'relative',
        ...boxSize,
      }}
      className={nodesDraggable && selected ? 'selected' : ''}
      key={componentKey}
    >
      <SVGElement
        SVGToRender={Element || WarningDiagramComponent()}
        style={nodeState}
      />
      {mixedPorts?.map((port) => (
        <Ports {...port} key={port?.id} />
      ))}
      {mixedFlairs &&
        // @ts-ignore
        mixedFlairs.map((flair) => (
          <Flairs
            flair={flair}
            key={flair.key}
            currentComponent={componentValue}
            systemId={systemId}
            handleDiagram={handleDiagram}
            handleNode={handleNode}
          />
        ))}
    </NodeWrap>
  );
};

DiagramComponent.defaultProps = {
  data: {},
};
(Flairs as React.ComponentType<ComponentFlairProps>).defaultProps = {};
FlairLabel.defaultProps = {
  prefix: '',
  suffix: '',
  size: 'M',
};
FlairBar.defaultProps = {
  orientation: 'VERTICAL',
  maximum: 100,
};

export default memo(DiagramComponent);
