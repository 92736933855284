import React from 'react';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { Grid } from '@mui/material';
import { LockOutlined, SearchRounded } from '@mui/icons-material';
import { Bold, EmptyBox, Light } from '../../styles/empty';
import { isForbiddenError } from '../../../redux/utils';

interface Props {
  isListView: boolean;
  searchValue: string;
  error: FetchBaseQueryError | SerializedError | string | undefined;
}

const EmptyVisualElement = (props: Props) => {
  const { isListView, searchValue, error } = props;

  if (isForbiddenError(error)) {
    return (
      <Grid sx={{ flexGrow: 1 }} item xs={12}>
        <EmptyBox sx={{ height: '100%', mt: isListView ? 2 : 0 }}>
          <LockOutlined />
          <Bold>No visuals available</Bold>
          <Light>You don&apos;t have access to the list of visuals</Light>
        </EmptyBox>
      </Grid>
    );
  }

  if (searchValue)
    return (
      <Grid sx={{ flexGrow: 1 }} item xs={12}>
        <EmptyBox sx={{ height: '100%', mt: isListView ? 2 : 0 }}>
          <SearchRounded className="icon" />
          <Bold>No element found</Bold>
          <Light>Try searching different word</Light>
        </EmptyBox>
      </Grid>
    );

  return (
    <Grid sx={{ flexGrow: 1 }} item xs={12}>
      <EmptyBox sx={{ height: '100%', mt: isListView ? 2 : 0 }}>
        <SearchRounded className="icon" />
        <Bold>No visuals found</Bold>
        <Light>Add a new visual by clicking the button above</Light>
      </EmptyBox>
    </Grid>
  );
};

export default EmptyVisualElement;
