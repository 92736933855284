import React, { useMemo } from 'react';
import { BrokenImageRounded } from '@mui/icons-material';
import { useGetVisualAssetQuery } from '../../../redux/services/visuals/api';
import { VisualResponse } from '../../../redux/services/visuals/types';
import { ImagePreviewStyled } from './styles';

export const PreviewItemComponent = ({
  visual,
}: {
  visual: VisualResponse;
}) => {
  const { id, version } = visual;
  const { data: imageText, isLoading } = useGetVisualAssetQuery({
    id,
    version,
  });

  const image = useMemo(() => {
    if (!imageText) return null;
    const svg = new Blob([imageText], { type: 'image/svg+xml' });
    const url = URL.createObjectURL(svg);
    return url;
  }, [imageText]);

  if (isLoading || imageText === 'undefined')
    return (
      <BrokenImageRounded
        sx={{
          color: 'secondary.main',
          opacity: 0.75,
        }}
      />
    );

  return (
    <ImagePreviewStyled
      src={image}
      alt={visual.name}
      id={`ElementLibrary-${visual.id}`}
    />
  );
};

export default PreviewItemComponent;
