export const capitalizeFirstLetter = (val: string): string =>
  `${val[0].toUpperCase()}${val.substring(1, val.length)}`;

type MainAction = 'create' | 'update';
export const notifications = {
  success: (action: MainAction) => `Site was ${action}d successfully`,
  defaultError: (action: MainAction) => `Failed to ${action} site`,
  permissionsError: (action: MainAction) =>
    `You do not have permission to ${action} a site`,
};

export const findCaseInsensitive = (findIn: string, find: string): boolean =>
  findIn?.toLocaleLowerCase().indexOf(find.toLocaleLowerCase()) > -1;

export interface EventTypesConverter {
  contains: string;
  convertTo: string;
}

export const parseEventTypeMessage = ({
  eventType,
  eventTypes,
}: {
  eventType: string;
  eventTypes: EventTypesConverter[];
}): string =>
  eventTypes.find(({ contains }) => eventType.includes(contains))?.convertTo;

const collator = new Intl.Collator('en', {
  numeric: true,
  sensitivity: 'base',
});
export const sortArrayByProperty = (array: any[], property: string) => {
  if (!array) return undefined;
  return [...array].sort((a, b) => collator.compare(a[property], b[property]));
};

export const colorToHex = (color: string) => {
  const a = document.createElement('div');
  a.style.color = color;
  const colors = window
    .getComputedStyle(document.body.appendChild(a))
    .color.match(/\d+/g)
    .map((c) => parseInt(c, 10));
  return colors.length >= 3
    ? // eslint-disable-next-line no-bitwise
      `#${((1 << 24) + (colors[0] << 16) + (colors[1] << 8) + colors[2])
        .toString(16)
        .substr(1)}`
    : '#000000';
};

export const hexToRGBA = (hexCode: string, opacity: string | number) => {
  let hex = hexCode.replace('#', '');

  if (hexCode.length === 3) {
    hex = `${hexCode[0]}${hexCode[0]}${hexCode[1]}${hexCode[1]}${hexCode[2]}${hexCode[2]}`;
  }

  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  return `rgba(${r},${g},${b},${+opacity / 100})`;
};
