import React, { useState } from 'react';
import {
  Box,
  Divider,
  ListItemIcon,
  Menu,
  MenuItem,
  Switch,
} from '@mui/material';
import {
  AccountCircleOutlined,
  AutoAwesomeOutlined,
  DarkModeRounded,
  LightModeRounded,
  SettingsOutlined,
} from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { ROUTES_PATH } from '../../constants';
import { ManageSettingsIcon, SignoutIcon } from '../../icons';
import { setIsDemo } from '../../redux/modules/isDemo/slice';
import { IAppState } from '../../typescript/interfaces/appstate.interface';
import EditProfile from '../../components/user-role/users/EditProfile';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { envVars } from '../../env';
import useOidcCloud from '../../hooks/useOidcCloud';
import { Head, UserAction, UserAvatar, UserName, UserRole } from './styles';

interface Props {
  anchorEl: any;
  handleClose: any;
  name?: string;
  role?: string;
  isDark: boolean;
  toggleTheme: any;
}

interface DemoModeToggleProps {
  handleClose: () => void;
}

type ManageUserButtonProps = {
  path: string;
  handleClose: () => void;
};

const ManageUserButton = ({ path, handleClose }: ManageUserButtonProps) => (
  <MenuItem
    onClick={handleClose}
    component={Link}
    to={path}
    sx={{ p: '6px' }}
    id="UserMenu-manage-users"
  >
    <ListItemIcon>
      <AccountCircleOutlined />
    </ListItemIcon>
    Manage users & roles
  </MenuItem>
);

const MenuItemComponent = ({
  handleEditProfileOpen,
}: {
  handleEditProfileOpen: any;
}) => (
  <MenuItem
    id="UserMenu-edit_profile_button"
    sx={{ p: '6px' }}
    onClick={handleEditProfileOpen}
  >
    <ListItemIcon>
      <SettingsOutlined />
    </ListItemIcon>
    Edit profile
  </MenuItem>
);

const DemoModeToggle = ({ handleClose }: DemoModeToggleProps) => {
  const dispatch = useAppDispatch();

  const isToggleAccessible = useAppSelector(
    (state: IAppState) => state.view.isToggleAccessible,
  );

  const isDemo = useAppSelector((state: IAppState) => state.isDemo);

  const enableDemo = () => {
    dispatch(setIsDemo(!isDemo));
    handleClose();
  };

  if (!isToggleAccessible || !envVars?.demoMode) return null;

  return (
    <MenuItem id="UserMenu-demo-mode" onClick={enableDemo} sx={{ p: '6px' }}>
      <ListItemIcon>
        <AutoAwesomeOutlined />
      </ListItemIcon>
      {isDemo ? 'Disable demo mode' : 'Enable demo mode'}
      <Box sx={{ ml: 2 }} />
      <Switch checked={isDemo} sx={{ ml: 'auto' }} />
    </MenuItem>
  );
};

const UserDropdown = (props: Props): JSX.Element => {
  const { anchorEl, handleClose, name, role, isDark, toggleTheme } = props;
  const [isEditProfile, setIsEditProfile] = useState(false);
  const { logout } = useOidcCloud();

  const handleEditProfileOpen = () => {
    setIsEditProfile(true);
    handleClose();
  };

  const handleEditProfileClose = () => {
    setIsEditProfile(false);
  };

  const onLogout = () => {
    logout();
    handleClose();
  };

  return (
    <>
      <Menu
        id="user-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        sx={{
          '& .MuiMenu-paper': {
            maxHeight: 'calc(100% - 32px)',
          },
        }}
      >
        <Head>Account</Head>
        <MenuItem sx={{ p: '6px', mb: 1 }}>
          <UserAction>
            <UserAvatar src="/" alt={name} />
            <div>
              <UserName>{name}</UserName>
              <UserRole variant="subtitle2">{role}</UserRole>
            </div>
          </UserAction>
        </MenuItem>
        <MenuItemComponent handleEditProfileOpen={handleEditProfileOpen} />
        <Box sx={{ px: '6px', mt: 1 }}>
          <Divider />
        </Box>
        <Head>Admin</Head>
        <MenuItem
          id={`UserMenu-Toggle-${isDark ? 'light' : 'dark'}-mode`}
          onClick={toggleTheme}
          sx={{ p: '6px' }}
        >
          <ListItemIcon>
            {isDark ? <LightModeRounded /> : <DarkModeRounded />}
          </ListItemIcon>
          {isDark ? 'Toggle light mode' : 'Toggle dark mode'}
          <Box sx={{ ml: 2 }} />
          <Switch checked={isDark} sx={{ ml: 'auto' }} />
        </MenuItem>
        <DemoModeToggle handleClose={handleClose} />
        <ManageUserButton
          path={`${ROUTES_PATH.USERS}?tab=users`}
          handleClose={handleClose}
        />
        <MenuItem
          onClick={handleClose}
          component={Link}
          to={ROUTES_PATH.SETTINGS}
          sx={{ p: '6px' }}
          id="UserMenu-manage-settings"
        >
          <ListItemIcon>
            <ManageSettingsIcon />
          </ListItemIcon>
          Manage settings
        </MenuItem>
        <Box sx={{ px: '6px', my: 1 }}>
          <Divider />
        </Box>
        <MenuItem
          id="UserMenu-sign-out"
          sx={{ p: '6px', color: 'error.main' }}
          onClick={onLogout}
        >
          <ListItemIcon>
            <SignoutIcon />
          </ListItemIcon>
          Sign out
        </MenuItem>
      </Menu>
      <EditProfile
        isOpened={isEditProfile}
        closeModal={handleEditProfileClose}
      />
    </>
  );
};

UserDropdown.defaultProps = {
  name: '',
  role: '',
};

export default UserDropdown;
