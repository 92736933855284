export const dateDiff = (date1, date2) => {
  const newDate1 = Date.UTC(
    date1.getFullYear(),
    date1.getMonth(),
    date1.getDate(),
  );
  const newDate2 = Date.UTC(
    date2.getFullYear(),
    date2.getMonth(),
    date2.getDate(),
  );
  const ms = Math.abs(newDate1 - newDate2);
  return Math.floor(ms / 1000 / 60 / 60 / 24);
};

export const parseToDeploymentDate = (date: Date) =>
  date?.toJSON()?.split('T')[0];

export const parseToLocalDate = (
  date: string,
  format?: { locale: string; options: Intl.DateTimeFormatOptions },
): string => {
  if (!date) return undefined;

  const convertedDate = new Date(date);

  if (`${convertedDate}`.toLowerCase().includes('invalid'))
    return `${convertedDate}`;

  const locale = format?.locale || 'en-GB';
  const options = format?.options || {
    day: '2-digit',
    month: 'long',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  };

  const newDate = new Intl.DateTimeFormat(locale, options).format(
    convertedDate,
  );

  return newDate;
};
