import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import {
  Box,
  Button,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
} from '@mui/material';
import {
  CodeRounded,
  EditOutlined,
  FileDownloadOutlined,
} from '@mui/icons-material';
import { useDropzone } from 'react-dropzone';
import {
  Actions,
  ContentWrap,
  DetailText,
  DetailTitle,
  Dropzone,
  RightContentWrap,
  ThumbWrap,
} from '../styles';
import MoreButtonComponent from '../MoreComponent';
import PreviewItemComponent from '../PreviewItemComponent';
import { ROUTES_PATH } from '../../../../constants';
import { TrashIcon } from '../../../../icons';
import { FORMDATA_ASSET, FORMDATA_DEFINITION } from '.';
import {
  useCreateVisualMutation,
  useDeleteVisualMutation,
  useGetVisualAssetQuery,
  useGetVisualDocumentQuery,
  useUpdateVisualMutation,
} from '../../../../redux/services/visuals/api';
import useSnackbar from '../../../../hooks/useSnackbar';
import InnerModal from '../InnerModal';
import ConfirmModal from '../ConfirmModal';
import { VisualResponse } from '../../../../redux/services/visuals/types';

interface Props {
  activeVisual: VisualResponse;
  refetch: () => void;
  isVisualLoading: boolean;
  setActiveVisualId: React.Dispatch<React.SetStateAction<string>>;
  onClose: () => void;
  handleAdd: () => void;
  isListView: boolean;
  selectedVisuals: string[];
}

type FileWithPreview = {
  file: File;
  preview: string;
};

const ModalContentRight = (props: Props) => {
  const {
    activeVisual,
    refetch,
    isVisualLoading,
    setActiveVisualId,
    isListView,
    onClose,
    handleAdd,
    selectedVisuals,
  } = props;

  const history = useHistory();
  const { showForbiddenError, showSuccess } = useSnackbar();

  const [acceptedAsset, setAcceptedAsset] = useState<FileWithPreview>();
  const [acceptedDefinition, setAcceptedDefinition] = useState<File>();

  const [isInnerModalOpened, setIsInnerModalOpened] = useState<boolean>(false);
  const [isConfirmDelete, setIsConfirmDelete] = useState<boolean>(false);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);

  const { data: visualAssetData, isLoading: isVisualFetching } =
    useGetVisualAssetQuery(
      {
        id: activeVisual.id,
        version: activeVisual?.version,
      },
      { skip: !activeVisual.id },
    );
  const { data: visualDocumentData, isLoading: isDocumentFetching } =
    useGetVisualDocumentQuery(activeVisual.id, {
      skip: !activeVisual.id,
    });

  const [
    createVisual,
    {
      error: createError,
      isSuccess: isCreated,
      isLoading: isCreating,
      data: createdVisual,
      reset: resetCreate,
    },
  ] = useCreateVisualMutation();

  const [
    updateVisual,
    {
      error: updateError,
      isSuccess: isUpdated,
      isLoading: isUpdating,
      data: updatedVisual,
      reset: resetUpdate,
    },
  ] = useUpdateVisualMutation();

  const [
    deleteVisual,
    {
      error: deleteError,
      isSuccess: isDeleted,
      isLoading: isDeleting,
      reset: resetDelete,
    },
  ] = useDeleteVisualMutation();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleConfirmOpen = () => {
    setIsConfirmDelete(true);
    setAnchorEl(null);
  };

  const handleConfirmClose = () => {
    setIsConfirmDelete(false);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setIsConfirmDelete(false);
  };

  const handleInnerModalOpen = () => {
    setIsInnerModalOpened(true);
    handleClose();
  };

  const handleInnerModalClose = () => {
    setIsInnerModalOpened(false);
  };

  const handleDelete = async () => {
    if (activeVisual.id) {
      deleteVisual(activeVisual.id);
      setIsConfirmDelete(false);
      handleClose();
    }
  };

  const handleRedirect = useCallback(() => {
    history.push(`${ROUTES_PATH.CREATE_VISUAL}/${activeVisual.id}`);
  }, [activeVisual.id]);

  const handleDefinitionFileDownload = () => {
    if (!visualDocumentData) return;
    const defBlob = new Blob([visualDocumentData], { type: 'text/plain' });
    const url = window.URL.createObjectURL(defBlob);
    const element = document.createElement('a');
    element.href = url;
    element.setAttribute('download', `${activeVisual.name}.yaml`);

    document.body.appendChild(element);
    element.click();

    handleClose();
  };

  const handleImageFileDownload = () => {
    if (!visualAssetData) return;
    const imgBlob = new Blob([visualAssetData], { type: 'text/plain' });
    const url = window.URL.createObjectURL(imgBlob);
    const element = document.createElement('a');
    element.href = url;
    element.setAttribute('download', `${activeVisual.name}.svg`);

    document.body.appendChild(element);
    element.click();

    handleClose();
  };

  const { getRootProps: getAssetRootProps, getInputProps: getAssetInputProps } =
    useDropzone({
      noKeyboard: true,
      accept: '.svg',
      disabled: isCreating || isDeleting || isUpdating,
      multiple: false,
      onDrop: async (acceptedFiles) => {
        const formData = new FormData();
        formData.append(FORMDATA_ASSET, acceptedFiles[0]);
        if (activeVisual.id) {
          // Immediately try saving if we are updating an existing visual
          await updateVisual({
            id: activeVisual.id,
            form: formData,
          });
          return;
        }
        if (acceptedDefinition !== null) {
          // Immdediately try creating the visual if the definition is provided already
          formData.append(FORMDATA_DEFINITION, acceptedDefinition);
          await createVisual(formData);
          return;
        }
        setAcceptedAsset({
          file: acceptedFiles[0],
          preview: URL.createObjectURL(acceptedFiles[0]),
        });
      },
    });

  const delayedRefetch = () => {
    setTimeout(() => {
      refetch();
    }, 500);
  };

  useEffect(() => {
    showForbiddenError({
      error: createError,
      customForbiddenMessage:
        "You don't have enough permissions to create visual",
      customDefaultMessage: 'Failed to create visual',
    });
    if (createError) resetCreate();
  }, [createError]);

  useEffect(() => {
    showForbiddenError({
      error: updateError,
      customForbiddenMessage:
        "You don't have enough permissions to update visual",
      customDefaultMessage: 'Failed to update visual',
    });
    if (updateError) resetUpdate();
  }, [updateError]);

  useEffect(() => {
    showForbiddenError({
      error: deleteError,
      customForbiddenMessage:
        "You don't have enough permissions to delete visual",
      customDefaultMessage: 'Failed to delete visual',
    });
    if (deleteError) resetDelete();
  }, [deleteError]);

  useEffect(() => {
    if (isCreated && createdVisual) {
      showSuccess(`Visual created: ${createdVisual.name}`);
      setActiveVisualId(createdVisual.id);
      resetCreate();
    }
    if (isUpdated && updatedVisual) {
      showSuccess(`Visual updated: ${updatedVisual.name}`);
      resetUpdate();
    }
    if (isDeleted) {
      showSuccess(`Visual deleted`);
      resetDelete();
    }
    if (isCreated || isUpdated || isDeleted) {
      // Hacky way of dealing with lateness of change propagation to visuals list query.
      // The mutation actually triggers an automatic refetch, but at that time,
      // the list query does not probably have the latest state.
      delayedRefetch();
    }
  }, [isCreated, createdVisual, isUpdated, updatedVisual, isDeleted]);

  return (
    <>
      <ContentWrap
        sx={{
          pl: { sm: 3, xs: 0 },
          flex: { xs: 0, sm: '1 1 40%' },
          maxWidth: { sm: '40%', xs: '100%' },
          maxHeight: { xs: '40%', sm: '100%' },
          flexGrow: 1,
        }}
      >
        <Stack
          sx={{
            overflow: 'auto',
            pl: { sm: 0, xs: 3 },
            pt: { sm: 0, xs: 2 },
            flexGrow: 1,
          }}
        >
          <ThumbWrap
            sx={{ display: { xs: 'none', sm: 'flex' } }}
            {...getAssetRootProps()}
          >
            <input {...getAssetInputProps()} />
            {(activeVisual || acceptedAsset) && (
              <PreviewItemComponent visual={activeVisual} />
            )}
            {!acceptedAsset && !activeVisual && (
              <Dropzone>Drag or click to select an image</Dropzone>
            )}
          </ThumbWrap>
          <RightContentWrap>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <DetailTitle noWrap>{activeVisual.name}</DetailTitle>
              <MoreButtonComponent
                menuOpen={menuOpen}
                handleClick={handleClick}
              />
              <Menu
                id="action-menu"
                anchorEl={anchorEl}
                open={menuOpen}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'action-button',
                }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <MenuItem
                  onClick={() => {
                    handleInnerModalOpen();
                  }}
                >
                  <ListItemIcon>
                    <EditOutlined />
                  </ListItemIcon>
                  Edit
                </MenuItem>
                <MenuItem
                  onClick={() => handleRedirect()}
                  disabled={isVisualLoading}
                >
                  <ListItemIcon>
                    <CodeRounded />
                  </ListItemIcon>
                  Open in editor
                </MenuItem>
                <MenuItem
                  onClick={handleDefinitionFileDownload}
                  disabled={isDocumentFetching}
                >
                  <ListItemIcon>
                    <FileDownloadOutlined />
                  </ListItemIcon>
                  Download definition file
                </MenuItem>
                <MenuItem
                  onClick={handleImageFileDownload}
                  disabled={isVisualFetching}
                >
                  <ListItemIcon>
                    <FileDownloadOutlined />
                  </ListItemIcon>
                  Download SVG
                </MenuItem>
                <MenuItem
                  sx={{ color: 'error.main' }}
                  onClick={handleConfirmOpen}
                >
                  <ListItemIcon>
                    <TrashIcon />
                  </ListItemIcon>
                  Delete element
                </MenuItem>
              </Menu>
            </Box>
            <DetailText>{activeVisual.description}</DetailText>
          </RightContentWrap>
          <Actions>
            <Button
              id="AddElement-cancel_button"
              onClick={onClose}
              variant="outlined"
              color="inherit"
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={handleAdd}
              disabled={!activeVisual}
              id="AddElement-add_button"
            >
              Add{' '}
              {isListView && selectedVisuals.length > 1
                ? 'selected'
                : 'element'}
            </Button>
          </Actions>
        </Stack>
      </ContentWrap>
      <InnerModal
        open={isInnerModalOpened}
        onClose={handleInnerModalClose}
        visual={activeVisual}
        setAcceptedDefinition={setAcceptedDefinition}
      />
      <ConfirmModal
        open={isConfirmDelete}
        name={activeVisual?.name}
        onClose={handleConfirmClose}
        handleDelete={handleDelete}
      />
    </>
  );
};

export default ModalContentRight;
