/* eslint-disable import/no-cycle */
/* eslint-disable no-shadow */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Button, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import { TabContext } from '@mui/lab';
import {
  AddRounded,
  FilterListRounded,
  SearchRounded,
} from '@mui/icons-material';
import Loader from '../../../common/Loader';
import Search from '../../../common/Search';
import TabPanel from '../../../common/TabPanel';
import SiteFilter from '../../../common/SiteFilter';
import SystemScanButton from '../SystemScanButton';
import {
  CONTROL_STATUSES,
  MACHINE_STATUSES,
  ROUTES_PATH,
} from '../../../constants';
import { useGetSitesQuery } from '../../../redux/services/sites/api';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { setSelectedSystemId } from '../../../redux/modules/user/actions';
import StatusFilter from '../StatusFilter';
import {
  LeftPanel,
  Root,
  SearchControl,
  SearchFilter,
  ToggleBtn,
} from './styles';
import SystemListNew from './SystemListNew';
import { setActiveSites } from '../../../redux/modules/sites/slice';
import { useGetSystemsQuery } from '../../../redux/services/systems/api';
import SystemListDetail from '../SystemListDetail';
import SystemEmpty from '../SystemEmpty';
import { parseToLocalDate } from '../../../utils/DateUtils';

interface PropsSystemList {
  status: string[];
  systems: string[];
  isSearch?: boolean;
  isFilter?: boolean;
  handleCreateOpen?: () => void;
  handleToggleFilter?: () => void;
  onCancelSearch?: () => void;
  onExpandSearch?: () => void;
  handleStatusChange?: (event: any) => void;
  systemStatuses?: string[];
  systemStatus?: string[];
  searchValue?: string;
  handleSearchInput?: (event: any) => void;
  isProjectSystem?: boolean;
  newCreatedSystem?: string;
  shouldRedirect?: boolean;
}
const getEmptySystemListDetails = (
  listLength?: number,
  isActiveSitesLoaded?: boolean,
  isFiltered?: boolean,
) => {
  if (!isActiveSitesLoaded || (isFiltered && listLength === 0)) {
    return {
      title: 'No systems found',
      detail: 'Please try different search term or adjust filtering.',
    };
  }
  return {};
};

function SystemLists(props: PropsSystemList) {
  const {
    status,
    systems,
    isSearch,
    isFilter,
    handleCreateOpen,
    handleToggleFilter,
    onCancelSearch,
    onExpandSearch,
    systemStatus,
    handleStatusChange,
    systemStatuses,
    searchValue,
    handleSearchInput,
    isProjectSystem,
    newCreatedSystem,
    shouldRedirect,
  } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [selection, setSelection] = useState<string[]>(['ALLSITES']);
  const [deletedSystem, setDeletedSystem] = useState<string | undefined>(
    undefined,
  );

  const controlStatuses = CONTROL_STATUSES;
  const machineStatuses = MACHINE_STATUSES;

  const selectedSystemId = useAppSelector(
    (state) => state.user.selectedSystemId,
  );
  const { data: systemsSelector, isLoading: isSystemsSelectorLoading } =
    useGetSystemsQuery(undefined, {
      pollingInterval:
        systems.indexOf(selectedSystemId || '') === -1 ? 5000 : 0,
    });

  const activeSites = useAppSelector((state) => state.activeSites.activeSites);
  const isActiveSitesLoaded = useAppSelector(
    (state) => state.activeSites.loaded,
  );
  const { data: sites = [] } = useGetSitesQuery();

  const [containerSize, setContainerSize] = useState({ height: 0, width: 0 });

  const dispatch = useAppDispatch();

  const isSystemsFiltered = selection[0] !== 'ALLSITES' || status[0] !== 'ALL';
  const noSystemFoundMessages = getEmptySystemListDetails(
    systems?.length,
    isActiveSitesLoaded,
    isSystemsFiltered,
  );

  useEffect(() => {
    if (sites?.length > 0 && (!activeSites || activeSites?.length === 0)) {
      dispatch(
        setActiveSites({ activeSites: sites.map((it) => it.id), loaded: true }),
      );
    }
  }, [sites]);

  const history = useHistory();
  const systemsRef = useRef(undefined);

  useEffect(() => {
    if (systemsRef?.current) {
      const height: number = systemsRef.current.offsetHeight;
      const width: number = systemsRef.current.offsetWidth;

      if (height !== containerSize.height || width !== containerSize.width)
        setContainerSize({ height, width });
    }
  }, [systemsRef?.current?.offsetHeight, systemsRef?.current?.offsetWidth]);

  const systemsLocalSelected = useMemo(() => {
    if (systems?.length === 0) return [];
    if (isSystemsSelectorLoading || systemsSelector?.length === 0) return [];

    const activeFilter = status.map((stat) => stat?.toLowerCase());

    return systems
      .map((id) =>
        systemsSelector?.find((systemInner) => systemInner.id === id),
      )
      ?.filter((system) =>
        isActiveSitesLoaded ? activeSites.indexOf(system?.site) > -1 : true,
      )
      ?.filter((system) => {
        if (!system) return false;
        const { state, display, machineState } = system;
        // has control and machine statuses filter both
        if (
          activeFilter.some((v) => controlStatuses.indexOf(v) > -1) &&
          activeFilter.some((v) => machineStatuses.indexOf(v) > -1)
        ) {
          if (
            activeFilter.indexOf(machineState?.toLowerCase()) > -1 &&
            activeFilter.indexOf(display?.toLowerCase()) > -1
          ) {
            return true;
          }
          if (
            activeFilter.indexOf(machineState?.toLowerCase()) > -1 &&
            activeFilter.indexOf('running') > -1 &&
            state > 0
          ) {
            return true;
          }
          if (
            activeFilter.indexOf(machineState?.toLowerCase()) > -1 &&
            activeFilter.indexOf('unknown') > -1 &&
            display === undefined
          ) {
            return true;
          }
        }

        // has any of control and machine statuses filter
        if (
          activeFilter.every((v) => controlStatuses.indexOf(v) > -1) ||
          activeFilter.every((v) => machineStatuses.indexOf(v) > -1)
        ) {
          if (activeFilter.indexOf(machineState?.toLowerCase()) > -1) {
            return true;
          }
          if (activeFilter.indexOf(display?.toLowerCase()) > -1) {
            return true;
          }
          if (activeFilter.indexOf('running') > -1 && state > 0) {
            return true;
          }
          if (activeFilter.indexOf('unknown') > -1 && display === undefined) {
            return true;
          }
        }

        if (activeFilter.indexOf('all') > -1) {
          return true;
        }
        return false;
      })
      ?.filter(
        (system) =>
          system.name?.toLowerCase().indexOf(searchValue.toLowerCase()) > -1,
      );
  }, [systems, systemsSelector, searchValue, status, activeSites]);

  useEffect(() => {
    if (systems.indexOf(selectedSystemId) === -1 && !selectedSystemId) {
      dispatch(setSelectedSystemId(systems[0]));
    }
  }, [JSON.stringify(systems), selectedSystemId]);

  useEffect(() => {
    if (
      systemsSelector?.length > 0 &&
      systems?.length > 0 &&
      systems?.indexOf(selectedSystemId) === -1
    ) {
      dispatch(setSelectedSystemId(systems[0]));
    }
  }, [systemsSelector?.length, systems?.length]);

  useEffect(() => {
    if (deletedSystem) {
      dispatch(
        setSelectedSystemId(systems.filter((id) => id !== deletedSystem)[0]),
      );
    }
  }, [deletedSystem]);

  const handleTabChange = useCallback(
    (id: string) => {
      if (isMobile)
        history.push(`${ROUTES_PATH.SYSTEM_EXPANDED}/${id}`, {
          from: isProjectSystem ? 'project' : 'system',
        });
      dispatch(setSelectedSystemId(id));
    },
    [isMobile, isProjectSystem],
  );
  const isSystemsListEmpty =
    systems?.length === 0 || systemsLocalSelected?.length === 0;
  return (
    <Root
      className="content-wrap"
      sx={{
        height: '100%',
        flexDirection: isSystemsListEmpty ? 'column' : 'row',
      }}
    >
      {isSystemsSelectorLoading ? (
        <Loader />
      ) : (
        <TabContext value={selectedSystemId}>
          <LeftPanel
            sx={{
              position: isSystemsListEmpty ? 'absolute' : 'relative',
              ...(isSystemsListEmpty ? { width: 'calc(100% - 2rem)' } : {}),
            }}
            ref={systemsRef}
          >
            <SearchFilter
              sx={{
                display: {
                  xs: isProjectSystem ? 'block' : 'none',
                  md: 'block',
                },
                mb: systems?.length === 0 && 0,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                  mb: 2,
                  minHeight: '40px',
                }}
              >
                {isSearch ? (
                  <SearchControl variant="outlined">
                    <Search
                      id="SystemList-search_input"
                      placeholder="Search"
                      value={searchValue}
                      onSearch={handleSearchInput}
                      onCancel={onCancelSearch}
                      autoFocus
                    />
                  </SearchControl>
                ) : (
                  <>
                    <Button
                      onClick={handleCreateOpen}
                      startIcon={<AddRounded />}
                      name="AddSystem"
                      sx={{ whiteSpace: 'nowrap' }}
                      variant="contained"
                      id="SystemList-Button_AddSystem"
                    >
                      Add system
                    </Button>
                    <SystemScanButton />
                  </>
                )}
                <Box sx={{ ml: isSearch ? 1 : 'auto' }}>
                  {!isSearch && (
                    <Tooltip title="Search">
                      <ToggleBtn
                        className={isSearch && 'active'}
                        onClick={onExpandSearch}
                        name="search"
                        aria-label="search"
                        id="System-search_button"
                      >
                        <SearchRounded />
                      </ToggleBtn>
                    </Tooltip>
                  )}
                  <Tooltip title="Filter">
                    <ToggleBtn
                      className={
                        (isFilter ||
                          status.toString() !== 'All' ||
                          sites?.length !== activeSites?.length) &&
                        'active'
                      }
                      onClick={handleToggleFilter}
                      name="filter"
                      aria-label="filter"
                      id="System-filter_button"
                    >
                      <FilterListRounded />
                    </ToggleBtn>
                  </Tooltip>
                </Box>
              </Box>
              <>
                {isFilter && (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '100%',
                      mb: '0.75rem',
                    }}
                  >
                    <SiteFilter
                      sx={{
                        ml: 0,
                        width: 'calc(50% - 0.375rem)',
                        mr: '0.375rem',
                      }}
                      setSelection={setSelection}
                      selection={selection}
                    />
                    <StatusFilter
                      value={systemStatus}
                      onChange={handleStatusChange}
                      statuses={systemStatuses}
                    />
                  </Box>
                )}
              </>
            </SearchFilter>
            <SystemListNew
              // @ts-ignore
              systems={systemsLocalSelected}
              onClickAction={handleTabChange}
              selectedItem={selectedSystemId}
              size={containerSize}
            />
          </LeftPanel>
          {!isMobile && (
            <>
              {systemsLocalSelected?.length > 0 && (
                <Box sx={{ display: 'flex', flex: 1 }}>
                  <Box sx={{ flexGrow: 1 }}>
                    {systemsLocalSelected.map((system) => {
                      const lastRun = parseToLocalDate(
                        `${new Date().toISOString()}`,
                      );
                      return (
                        <TabPanel
                          value={selectedSystemId}
                          index={system?.id || ''}
                          key={`tab_panel_${system?.id}`}
                          sx={{
                            paddingTop: 0,
                            paddingRight: 0,
                            paddingLeft: 1,
                            height: '100%',
                          }}
                        >
                          <SystemListDetail
                            id={system?.id || ''}
                            lastEdited={lastRun}
                            actions={['deploy', 'open', 'more']}
                            className=""
                            isProjectSystem={isProjectSystem}
                            shouldRedirect={shouldRedirect}
                            setDeleted={setDeletedSystem}
                            componentsCustomUI={[]}
                          />
                        </TabPanel>
                      );
                    })}
                  </Box>
                </Box>
              )}
            </>
          )}
          {systemsLocalSelected?.length === 0 && (
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                height: '100%',
                alignItems: 'center',
              }}
            >
              <SystemEmpty
                title={noSystemFoundMessages?.title}
                detail={noSystemFoundMessages?.detail}
                onCreate={handleCreateOpen}
              />
            </Box>
          )}
        </TabContext>
      )}
    </Root>
  );
}

const defaultProps = {
  isSearch: false,
  isFilter: false,
  handleCreateOpen: () => {},
  handleToggleFilter: () => {},
  onCancelSearch: () => {},
  onExpandSearch: () => {},
  handleStatusChange: () => {},
  systemStatuses: [{}],
  systemStatus: [{}],
  searchValue: '',
  handleSearchInput: () => {},
  isProjectSystem: false,
  newCreatedSystem: undefined,
  shouldRedirect: true,
};

SystemLists.defaultProps = defaultProps;

export default SystemLists;
