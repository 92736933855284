import React, { useEffect, useState } from 'react';
import {
  Alert,
  alpha,
  Checkbox,
  DialogContent,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Radio,
  RadioGroup,
  SelectChangeEvent,
  styled,
  Tooltip,
  Typography,
} from '@mui/material';
import { CloseRounded } from '@mui/icons-material';
import { useDropzone } from 'react-dropzone';
import DialogComponent from '../../../Dialogs/DialogComponent';
import { StyledSelect, UploadWrap } from '../../../Dialogs/styles';
import { Flex } from '../../../styles';
import { useGetVisualCategoriesQuery } from '../../../../redux/services/visualCategory/api';
import { capitalizeFirstLetter } from '../../../../utils/stringUtils';

const Item = styled(Flex)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  justifyContent: 'space-between',
}));

const StyledLabel = styled(FormControlLabel)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  margin: 0,
  flex: 1,
  '&.active': {
    borderColor: alpha(theme.palette.secondary.main, 0.7),
    backgroundColor: alpha(theme.palette.secondary.main, 0.1),
  },
}));

interface Props {
  open: boolean;
  onClose: () => void;
}

const ImportExport = (props: Props) => {
  const { open, onClose } = props;
  const [files, setFiles] = useState<File[]>([]);
  const [selectedValue, setSelectedValue] = useState<'import' | 'export'>(
    'import',
  );
  const [groupName, setGroupName] = useState<string[]>([]);

  const { data: visualCategories, isLoading: isVisualsCategoriesLoading } =
    useGetVisualCategoriesQuery();

  const handleGroupChange = (
    ev: SelectChangeEvent<string[] | unknown>,
    _child: React.ReactNode,
  ) => {
    const { value } = ev.target;
    setGroupName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : (value as string[]),
    );
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(
      (event.target as HTMLInputElement).value as 'import' | 'export',
    );
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        ),
      );
    },
  });

  useEffect(
    () => () =>
      files.forEach(async (file) => URL.revokeObjectURL(await file.text())),
    [],
  );

  const uploadError = true;
  return (
    <DialogComponent
      isOpened={open}
      closeModal={onClose}
      title="Import & Export"
      actionTitle={capitalizeFirstLetter(selectedValue)}
      handleAction={onClose}
    >
      <DialogContent>
        <RadioGroup
          row
          aria-labelledby="ImportExport-radio-buttons-group-label"
          name="radio-buttons-group"
          onChange={handleChange}
          value={selectedValue}
          sx={{ mb: 4, gap: 2 }}
        >
          <StyledLabel
            id="ImportExport-radio-import"
            value="import"
            control={<Radio />}
            label="Import components"
            className={selectedValue === 'import' ? 'active' : ''}
          />
          <StyledLabel
            id="ImportExport-radio-export"
            value="export"
            control={<Radio />}
            label="Export components"
            className={selectedValue === 'export' ? 'active' : ''}
          />
        </RadioGroup>
        {selectedValue === 'import' ? (
          <>
            {uploadError && (
              <Alert sx={{ mb: 1 }} severity="error">
                Could not upload the file
              </Alert>
            )}
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              {files.length < 1 ? (
                <UploadWrap>
                  {isDragActive ? (
                    <p>Drop the files here ...</p>
                  ) : (
                    <p>Drag & drop a .ZIP file or click to upload</p>
                  )}
                </UploadWrap>
              ) : (
                files.map((file) => (
                  <Item key={file.name}>
                    <Typography>{file.name}</Typography>
                    <Tooltip title="Remove">
                      <IconButton
                        size="small"
                        sx={{ mr: '-4px', opacity: 0.6 }}
                      >
                        <CloseRounded />
                      </IconButton>
                    </Tooltip>
                  </Item>
                ))
              )}
            </div>
          </>
        ) : (
          <FormControl fullWidth>
            <InputLabel shrink htmlFor="ExportComponent">
              Export group
            </InputLabel>
            <StyledSelect
              labelId="ExportComponent-label"
              id="ExportComponent"
              multiple
              value={groupName}
              onChange={handleGroupChange}
              // @ts-ignore
              renderValue={() => groupName.join(', ')}
              disabled={isVisualsCategoriesLoading}
            >
              {visualCategories.map((group) => (
                <MenuItem key={group.id} value={group.name}>
                  <Checkbox
                    sx={{ ml: -2 }}
                    checked={groupName.indexOf(group.name) > -1}
                  />
                  <ListItemText primary={group.name} />
                </MenuItem>
              ))}
            </StyledSelect>
          </FormControl>
        )}
      </DialogContent>
    </DialogComponent>
  );
};

export default ImportExport;
