import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
} from '@mui/material';
import { AddRounded } from '@mui/icons-material';
import Input from '../Input';
// eslint-disable-next-line import/no-cycle
import { ROUTES_PATH } from '../../constants';
import useSnackbar from '../../hooks/useSnackbar';
import { useGetProjectsQuery } from '../../redux/services/ensProjects/api';
import { useGetSitesQuery } from '../../redux/services/sites/api';
import {
  extendedApi,
  useCreateSystemMutation,
} from '../../redux/services/systems/api';
import DialogComponent from './DialogComponent';
import { ModalContent, StyledAutocomplete } from './styles';
import CreateSite from './CreateSite';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { setSelectedSystemId } from '../../redux/modules/user';
import { setRedirect } from '../../redux/modules/redirect';
import useOidcCloud from '../../hooks/useOidcCloud';
import { useGetCurrentUserQuery } from '../../redux/services/atreus/api';
import { IAppState } from '../../typescript/interfaces/appstate.interface';

interface Props {
  isOpened: boolean;
  closeModal: () => void;
  projectId?: string;
  onCreate?: (systemId: string) => void;
  shouldRedirect?: boolean;
  setNewCreatedSystem?: (systemId: string) => void;
}

const CreateSystem = (props: Props): JSX.Element => {
  const {
    isOpened,
    closeModal,
    projectId,
    shouldRedirect,
    setNewCreatedSystem,
  } = props;
  const [createSiteOpen, setCreateSiteOpen] = useState(false);

  const isEdgeEnv = useAppSelector(
    (state: IAppState) => state.environment === 'edge',
  );

  const { accessToken } = useOidcCloud();

  const { data: user } = useGetCurrentUserQuery(accessToken, {
    skip: !accessToken && !isEdgeEnv,
  });
  const { data: projects } = useGetProjectsQuery(undefined);

  const params = useParams<{ id: string }>();
  const { data: sitesSelector } = useGetSitesQuery();
  const [createSystem, { data: newCreatedSystem, error: systemCreationError }] =
    useCreateSystemMutation();

  const { showForbiddenError, showSuccess } = useSnackbar();

  const dispatch = useAppDispatch();
  const location = useLocation();

  const sites = useMemo(
    () => sitesSelector?.filter(({ id }) => id !== 'ALLSITES'),
    [sitesSelector],
  );

  const initialState = {
    name: '',
    description: '',
    site: '',
    createdBy: user?.id,
    project: '',
  };
  const [state, setState] = useState(initialState);

  const isUnderProject = useMemo(
    () => location.pathname.startsWith(ROUTES_PATH.PROJECT_DETAIL),
    [location],
  );
  const isActionButtonDisabled = useMemo(() => {
    const { project } = state;
    const stateName = state.name.trim();
    const description = state.description.trim();

    return (
      !stateName ||
      stateName.length > 40 ||
      description.length > 1024 ||
      !project ||
      !state.site
    );
  }, [state, sites]);

  useEffect(() => {
    setState((prev) => {
      if (isUnderProject) {
        const project = params?.id;
        prev = { ...prev, project };
      }
      return prev;
    });
  }, [params, isUnderProject]);

  useEffect(() => {
    if (setNewCreatedSystem && newCreatedSystem?.id) {
      setNewCreatedSystem(newCreatedSystem?.id);
    }
  }, [setNewCreatedSystem, newCreatedSystem]);

  const handleChange =
    (name: string) => (event: { target: { value: string } }) => {
      setState({ ...state, [name]: event.target.value });
    };

  useEffect(() => {
    if (!isUnderProject) setState({ ...state, project: projectId });
  }, [projectId]);

  const handleCreateSystem = async () => {
    // @ts-ignore
    const { data } = await createSystem({
      ...state,
      attachToProject: state.project,
    });
    if (data?.id) {
      setTimeout(() => {
        dispatch(
          extendedApi.util.updateQueryData(
            'getSystems',
            undefined,
            (systemsDraft) => {
              if (systemsDraft.find((s) => s.id === data.id)) {
                return systemsDraft;
              }
              return systemsDraft.concat(data);
            },
          ),
        );
      }, 2500);
    }

    if (params.id) {
      setState((prev) => ({ ...initialState, project: prev.project }));
    }
  };

  useEffect(() => {
    if (newCreatedSystem?.id) {
      dispatch(setSelectedSystemId(newCreatedSystem?.id));
      showSuccess(
        `${newCreatedSystem?.name} system has been successfully created`,
      );
      if (shouldRedirect) {
        dispatch(
          setRedirect({
            route: `${ROUTES_PATH.SYSTEM_DETAIL}/${newCreatedSystem?.id}`,
          }),
        );
      }
      setState(initialState);
      closeModal();
    }
  }, [newCreatedSystem]);

  useEffect(() => {
    showForbiddenError({
      error: systemCreationError,
      customForbiddenMessage:
        "You don't have enough permissions to create a system",
      customDefaultMessage: "System hasn't been created. Something went wrong",
    });
  }, [systemCreationError]);

  const closeModalHandle = () => {
    closeModal();
    if (params.id) {
      setState((prev) => ({ ...initialState, project: prev.project }));
    } else {
      setState(initialState);
    }
  };

  function handleSiteCreate(siteId: string) {
    setState({ ...state, site: siteId });
  }

  return (
    <DialogComponent
      isOpened={isOpened}
      closeModal={closeModalHandle}
      title="Add a system"
      actionTitle="Add"
      handleAction={handleCreateSystem}
      isActionButtonDisabled={isActionButtonDisabled}
      cancelId="AddSystem-cancel"
      actionId="AddSystem-add"
    >
      <ModalContent>
        <FormControl fullWidth>
          <InputLabel shrink htmlFor="name">
            <span>System name</span>
            <span>{state.name.length}/40</span>
          </InputLabel>
          <Input
            value={state.name}
            onChange={handleChange('name')}
            placeholder="Enter system name"
            id="name"
            inputProps={{
              maxLength: 40,
            }}
          />
        </FormControl>
        {!isUnderProject && (
          <FormControl fullWidth>
            <InputLabel shrink htmlFor="project">
              Project
            </InputLabel>
            <StyledAutocomplete
              id="project"
              options={projects}
              getOptionLabel={(option: any) => {
                const op = projects.find(
                  (s) => s?.id === option?.id || s?.id === option,
                );
                return op?.name || '';
              }}
              value={state.project || null}
              disabled={projects?.length === 0 || projectId != null}
              renderInput={(param) => (
                <>
                  {projects?.length > 0 ? (
                    <TextField
                      {...param}
                      variant="outlined"
                      placeholder="Select projects"
                    />
                  ) : (
                    <MenuItem value={0}>No available projects</MenuItem>
                  )}
                </>
              )}
              onChange={(e, newVal: any) => {
                setState((prev) => ({ ...prev, project: newVal?.id }));
              }}
              displayEmpty
            />
          </FormControl>
        )}
        <Box display="flex" alignItems="flex-end" sx={{ mb: 3 }}>
          <FormControl sx={{ mb: 0 }} fullWidth>
            <InputLabel shrink htmlFor="site">
              Site
            </InputLabel>
            <StyledAutocomplete
              id="site"
              options={sites}
              getOptionLabel={(option: any) => {
                const op = sites.find(
                  (s) => s?.id === option?.id || s?.id === option,
                );
                return op?.name || '';
              }}
              value={state.site || null}
              disabled={sites?.length === 0}
              renderInput={(param) => (
                <>
                  {sites?.length > 0 ? (
                    <TextField
                      {...param}
                      variant="outlined"
                      placeholder="Select site"
                    />
                  ) : (
                    <MenuItem value={0}>No available sites</MenuItem>
                  )}
                </>
              )}
              onChange={(e, newVal: any) => {
                setState((prev) => ({ ...prev, site: newVal?.id }));
              }}
              displayEmpty
            />
          </FormControl>
          <Button
            startIcon={<AddRounded />}
            onClick={() => setCreateSiteOpen(true)}
            variant="outlined"
            sx={{
              minWidth: '6.5rem',
              ml: 1,
            }}
            id="CreateSystemModal-Button_CreateSite"
          >
            Create
          </Button>
          {createSiteOpen ? (
            <CreateSite
              isOpened={createSiteOpen}
              onClose={() => setCreateSiteOpen(false)}
              onCreate={handleSiteCreate}
            />
          ) : null}
        </Box>
        <FormControl sx={{ marginBottom: '0' }} fullWidth>
          <InputLabel shrink htmlFor="description">
            <span>Description</span>
            <span>{state.description.length}/1024</span>
          </InputLabel>
          <Input
            value={state.description}
            onChange={handleChange('description')}
            placeholder="Enter description"
            id="description"
            multiline
            rows={4}
            maxRows={8}
            sx={{ p: 0 }}
            inputProps={{
              maxLength: 1024,
            }}
          />
        </FormControl>
      </ModalContent>
    </DialogComponent>
  );
};

CreateSystem.defaultProps = {
  projectId: undefined,
  onCreate: undefined,
  shouldRedirect: true,
  setNewCreatedSystem: () => {},
};

export default CreateSystem;
