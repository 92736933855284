import {
  AccordionSummary,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItemButton,
  Stack,
  Typography,
  alpha,
  styled,
} from '@mui/material';
import { Flex } from '../../styles';
import { LightTooltip } from '../../LightTooltip';

export const ModalComponent = styled(Dialog)({
  '& .MuiDialog-paper': {
    flexDirection: 'row',
    overflow: 'inherit',
    alignItems: 'stretch',
    width: '960px',
    minHeight: '500px',
    maxWidth: 'inherit',
    '@media(max-height: 600px)': {
      maxHeight: '90%',
      minHeight: 'auto',
    },
  },
});

export const ListItem = styled(Box)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  fontSize: '1.125rem',
  '&.active': {
    backgroundColor: alpha(theme.palette.secondary.main, 0.15),
    fontWeight: 600,
  },
}));

export const GridItem = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '120px',
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  backgroundColor: '#fff',
  '&.active': {
    backgroundColor:
      theme.palette.mode === 'light'
        ? alpha(theme.palette.secondary.main, 0.15)
        : '#e9f1f7',
    borderColor: theme.palette.secondary.main,
  },
  '&:hover': {
    backgroundColor:
      theme.palette.mode === 'light'
        ? alpha(theme.palette.secondary.main, 0.15)
        : '#e9f1f7',
  },
}));

export const Root = styled(Stack)(({ theme }) => ({
  position: 'absolute',
  right: 0,
  top: 0,
  bottom: 0,
  zIndex: 9,
  width: '300px',
  backgroundColor: theme.palette.background.default,
  borderLeft: `1px solid ${theme.palette.divider}`,
  paddingBottom: theme.spacing(1),
}));

export const ImagePreview = styled('img')(() => ({
  maxWidth: '100%',
  maxHeight: '100%',
}));

export const TinyPreviewWrap = styled('span')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 20,
  height: 20,
  '& img': {
    overflow: 'hidden',
    textIndent: '-100vw',
  },
}));

export const CloseBtn = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: 'calc(50% - 20px)',
  height: 40,
  width: 32,
  left: -32,
  borderTopRightRadius: 0,
  borderBottomRightRadius: 0,
  borderRight: '0 !important',
  backgroundColor: theme.palette.background.default,
  '&:hover': {
    backgroundColor: theme.palette.background.default,
  },
}));

export const Head = styled(AccordionSummary)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  '& .MuiAccordionSummary-content': {
    gap: theme.spacing(1),
    alignItems: 'center',
    '&.Mui-expanded': {
      fontWeight: 600,
    },
  },
  '& svg': {
    color: theme.palette.text.disabled,
  },
}));

export const Title = styled(Typography)(({ theme }) => ({
  fontSize: '1.125rem',
  color: theme.palette.text.primary,
}));

export const ModalTitleHead = styled(DialogTitle)(({ theme }) => ({
  fontSize: '1.5rem',
  whiteSpace: 'nowrap',
  marginRight: theme.spacing(2),
  padding: '0 !important',
}));

export const DeleteBtn = styled(Button)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.error.main, 0.12),
  color: theme.palette.error.main,
  '&:hover': {
    color: '#fff',
    backgroundColor: theme.palette.error.main,
  },
}));

export const CardHeadRoot = styled(DialogTitle)({
  display: 'flex',
  alignItems: 'center',
});

export const CardRoot = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
}));

export const CardHead = styled(Flex)(({ theme }) => ({
  justifyContent: 'space-between',
  fontSize: '1.125rem',
  color: theme.palette.text.primary,
  cursor: 'pointer',
}));

export const IconBtn = styled(IconButton)({
  width: 30,
  height: 30,
});

export const CardListItem = styled(ListItemButton)({
  padding: 0,
  justifyContent: 'space-between',
  '& .MuiIconButton-root': {
    opacity: 0,
  },
  '&:hover': {
    '& .MuiIconButton-root': {
      opacity: 1,
    },
  },
});

export const ModalHeadRoot = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
}));

export const InnerHead = styled(Box)({
  display: 'flex',
  width: '100%',
  justifyContent: 'flex-end',
});

export const SearchBtn = styled(IconButton)(({ theme }) => ({
  backgroundColor: 'transparent',
  marginRight: theme.spacing(1),
  border: `1px solid ${theme.palette.divider}`,
  color: theme.palette.mode === 'dark' ? '#fff' : theme.palette.secondary.main,
  '&:hover': {
    backgroundColor: 'transparent',
  },
  '&:focus': {
    backgroundColor: 'transparent',
  },
  '&:active': {
    backgroundColor: 'transparent',
  },
  '&.active': {
    backgroundColor: theme.palette.background.paper,
  },
}));

export const AddBtn = styled(IconButton)(({ theme }) => ({
  backgroundColor: 'transparent',
  marginRight: theme.spacing(1),
  border: `1px solid ${theme.palette.divider}`,
  color: theme.palette.mode === 'dark' ? '#fff' : theme.palette.secondary.main,
  '&:hover': {
    backgroundColor: 'transparent',
  },
  '&:focus': {
    backgroundColor: 'transparent',
  },
  '&:active': {
    backgroundColor: 'transparent',
  },
  '&.active': {
    backgroundColor: theme.palette.background.paper,
  },
}));

export const ContentWrap = styled(Stack)({
  height: '100%',
  maxHeight: '100%',
});

export const GridWrap = styled(Box)({
  flex: 1,
  overflow: 'auto',
  display: 'block',
  alignItems: 'center',
});

export const RightContentWrap = styled(Box)(({ theme }) => ({
  overflow: 'auto',
  flex: 1,
  paddingRight: theme.spacing(3),
  '@media(max-height: 600px)': {
    overflow: 'initial',
  },
}));

export const Dropzone = styled(Box)(({ theme }) => ({
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  fontSize: '1.125rem',
  backgroundColor: alpha(theme.palette.secondary.main, 0.15),
  border: '1px dotted',
  borderRadius: theme.shape.borderRadius,
  margin: theme.spacing(2),
  marginLeft: 0,
}));

export const ThumbWrap = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  backgroundColor: '#fff',
  minHeight: '260px',
  maxHeight: '320px',
  flex: 1,
  margin: theme.spacing(3),
  marginLeft: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(4),
  '& img': {
    height: 'auto',
    cursor: 'pointer',
    maxHeight: '100%',
    maxWidth: '100%',
  },
  '&:hover:after': {
    content: '"Change image"',
    position: 'absolute',
    display: 'block',
    padding: '0.5em',
    borderRadius: theme.shape.borderRadius,
    background: theme.palette.background.default,
    cursor: 'pointer',
    border: `1px solid ${theme.palette.text.secondary}`,
  },
}));

export const DetailTitle = styled(Typography)(({ theme }) => ({
  fontSize: '1.25rem',
  color: theme.palette.text.secondary,
  fontWeight: 600,
  marginRight: theme.spacing(2),
}));

export const DetailText = styled(Typography)(({ theme }) => ({
  fontSize: '1.125rem',
  color: theme.palette.text.secondary,
  marginTop: 0,
}));

export const Actions = styled(DialogActions)(({ theme }) => ({
  marginTop: 'auto',
  padding: theme.spacing(3),
}));

export const ImagePreviewStyled = styled('img')(() => ({
  maxWidth: '100%',
  maxHeight: '100%',
}));

export const TransferModalContent = styled(DialogContent)(({ theme }) => ({
  overflow: 'hidden',
  display: 'flex',
  gap: theme.spacing(2),
}));

export const TransferCardWrap = styled(Stack)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  marginTop: theme.spacing(1),
  gap: theme.spacing(1),
  padding: theme.spacing(1),
  flex: 1,
  overflow: 'hidden',
  backgroundColor: theme.palette.background.default,
}));

export const TransferList = styled(List)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.paper,
  flex: 1,
  padding: 4,
}));

export const SubTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  color: alpha(theme.palette.text.secondary, 0.8),
}));

export const Tooltip = styled(LightTooltip)({
  '& .MuiTooltip-tooltip': {
    backgroundColor: '#fff',
    borderColor: '#EBF4F9',
  },
});
export const CategoryTitle = styled(Typography)(({ theme }) => ({
  fontSize: '1.25rem',
  color: theme.palette.text.secondary,
  fontWeight: 600,
  marginBottom: theme.spacing(2),
  marginTop: -24,
  position: 'sticky',
  top: -24,
  backgroundColor: theme.palette.background.default,
}));
