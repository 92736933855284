import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Box, useTheme } from '@mui/material';
import { editor } from 'monaco-editor/esm/vs/editor/editor.api';
import { setDiagnosticsOptions } from 'monaco-yaml';
import { Resizable } from 're-resizable';
import cn from 'classnames';
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
import YamlWorker from 'worker-loader!monaco-yaml/yaml.worker.js';
import * as monaco from 'monaco-editor';
import './index.css';
import { generateSchema, templateSchema } from './schema';
import { YAML_LANGUAGE } from '../../../constants';
import ProblemsBlock from '../Editor/ProblemsBlock';
import { useSystemDefinitionQuery } from '../../../redux/services/documentSchemas/api';
import { useValidateProjectYAMLMutation } from '../../../redux/services/ensProjects/api';
import { useValidateSystemConfigurationTemplateMutation } from '../../../redux/services/systemConfigurationTemplate/api';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import {
  dropTemplateAction,
  setTemplateIsFocusEditor,
} from '../../../redux/modules/editor/slice';
import useYAMLTemplateEditorState from '../../../hooks/useYAMLTemplateEditorState';
import useYAMLEditor from '../../../hooks/useYAMLEditor';
import useGetPermissions from '../../../hooks/useGetPermissions';
import useDebounce from '../../../hooks/useDebounce';
import { Wrapper } from './styles';
import { ActionsType } from '../../../typescript/interfaces/editor.interface';
import { IAppState } from '../../../typescript/interfaces/appstate.interface';
import { nodeSelectAndHighlight } from '../../../utils/editorPureFn';

window.MonacoEnvironment = {
  getWorker(_moduleId, label) {
    if (label?.toLocaleLowerCase() !== YAML_LANGUAGE) return undefined;
    return new YamlWorker();
  },
};

setDiagnosticsOptions({
  validate: false,
  enableSchemaRequest: false,
  format: true,
  hover: true,
  completion: true,
  isKubernetes: true,
});

interface Props {
  configID: string;
}

export interface IDataValidate {
  data: {
    detail: {
      loc: string[];
      msg: string;
      type: string;
    }[];
  };
}

function createDependencyProposals(range) {
  // returning a static list of proposals, not even looking at the prefix (filtering is done by the Monaco editor),
  // here you could do a server side lookup
  return [
    {
      label: 'LifeSciComponent',
      kind: monaco.languages.CompletionItemKind.Function,
      documentation: [
        'LifeSciComponent:',
        '  componentType: hidden',
        '  instruments:',
        '    reporting:',
        '      attributes:',
        '        configuration:',
        '          default:',
        '            phase_data: []',
        '            recipe_data: []',
        '            trend_fields: []',
        '            value_fields: []',
        '          stateSchemaId: paracloud.ai/schemas/LifeSciComponent/BatchReports/configuration',
        '  ports: {}',
        '  render:',
        '  - -1067',
        '  - 471',
      ].join('\n'),
      insertText: [
        'LifeSciComponent:',
        '  componentType: hidden',
        '  instruments:',
        '    reporting:',
        '      attributes:',
        '        configuration:',
        '          default:',
        '            phase_data: []',
        '            recipe_data: []',
        '            trend_fields: []',
        '            value_fields: []',
        '          stateSchemaId: paracloud.ai/schemas/LifeSciComponent/BatchReports/configuration',
        '  ports: {}',
        '  render:',
        '  - -1067',
        '  - 471',
      ].join('\n'),
      range,
    },
    {
      label: 'phase_results',
      kind: monaco.languages.CompletionItemKind.Function,
      documentation: [
        'phase_results:',
        '  componentType: hidden',
        '  instruments:',
        '    batch_report:',
        '      attributes:',
        '        configuration:',
        '          default:',
        '            trend_fields: []',
        '            value_fields: []',
        '          stateSchemaId: paracloud.ai/schemas/LifeSciDigital/BatchReports/configuration',
        '        last_run:',
        '          default:',
        '            events: []',
        "            final_state: ''",
        '            recipe_parameters: []',
        '          stateSchemaId: paracloud.ai/schemas/LifeSciDigital/BatchReports/run',
        '  ports: {}',
        '  render:',
        '  - -1067',
        '  - 471',
      ].join('\n'),
      insertText: [
        'phase_results:',
        '  componentType: hidden',
        '  instruments:',
        '    batch_report:',
        '      attributes:',
        '        configuration:',
        '          default:',
        '            trend_fields: []',
        '            value_fields: []',
        '          stateSchemaId: paracloud.ai/schemas/LifeSciDigital/BatchReports/configuration',
        '        last_run:',
        '          default:',
        '            events: []',
        "            final_state: ''",
        '            recipe_parameters: []',
        '          stateSchemaId: paracloud.ai/schemas/LifeSciDigital/BatchReports/run',
        '  ports: {}',
        '  render:',
        '  - -1067',
        '  - 471',
      ].join('\n'),
      range,
    },
  ];
}

monaco.languages.registerCompletionItemProvider('yaml', {
  provideCompletionItems: (model, position) => {
    // find out if we are completing a property in the 'components'
    const textUntilPosition = model.getValueInRange({
      startLineNumber: 1,
      startColumn: 1,
      endLineNumber: position.lineNumber,
      endColumn: position.column,
    });
    const match = textUntilPosition.match(/components:\s\s\s/);
    if (!match) {
      return { suggestions: [] };
    }
    const word = model.getWordUntilPosition(position);
    const range = {
      startLineNumber: position.lineNumber,
      endLineNumber: position.lineNumber,
      startColumn: word.startColumn,
      endColumn: word.endColumn,
    };
    return {
      suggestions: createDependencyProposals(range),
    };
  },
});

const EditArea = (props: Props) => {
  const { configID } = props;

  const dispatch = useAppDispatch();
  const theme = useTheme();
  const ref = useRef(null);
  const { isActionGranted: isEditorHandlingGranted } = useGetPermissions({
    permissionsPath: configID
      ? 'structure:systemConfigurationTemplates:create'
      : 'structure:projects:update',
  });

  const [errorValidMarkers, setErrorValidMarkers] = useState<
    monaco.editor.IMarker[]
  >([]);
  const [errorMarkers, setErrorMarkers] = useState<editor.IMarker[]>([]);
  const [isUpdated, setIsUpdated] = useState<boolean>(false);

  const [isProblemsBlockExpanded, setIsProblemsBlockExpanded] = useState(false);
  const [isProblemsBlockOpened, setIsProblemsBlockOpened] = useState(false);

  const { text, handleTextValue, handleLineSelect } =
    useYAMLTemplateEditorState({ id: configID });
  const { yamlInstance } = useYAMLEditor({ isSetMarkers: false });

  const { data: schema, isError: isValidationSchemaLoadingError } =
    useSystemDefinitionQuery();

  const debouncedLocalValueForValid = useDebounce(text, 2000);

  const action = useAppSelector(
    (state: IAppState) => state.editor.template.action,
  );

  const [
    validateProjectYAML,
    { error: projectValidError, isSuccess: isValidateProjectYAMLSuccess },
  ] = useValidateProjectYAMLMutation();
  const [
    validateSystemConfigurationTemplate,
    {
      error: templateValidError,
      isSuccess: isValidateSystemConfigurationTemplateSuccess,
    },
  ] = useValidateSystemConfigurationTemplateMutation();

  useEffect(() => {
    editor.getEditors().forEach((edit) => {
      edit.dispose();
    });

    const yamlInstanceLocal = editor?.create?.(ref.current, {
      automaticLayout: true,
      language: YAML_LANGUAGE,
      fontSize: 15,
      minimap: {
        scale: 1.5,
      },
      contextmenu: false,
      tabSize: 2,
      scrollBeyondLastLine: false,
      quickSuggestions: { other: true, strings: true },
    });

    yamlInstanceLocal.onDidChangeModelContent(() => {
      const model = editor.getEditors()?.[0].getModel();
      const newVal = model.getValue();
      handleTextValue(typeof newVal === 'string' ? newVal : '');
      setIsUpdated(true);
    });

    yamlInstanceLocal.onDidFocusEditorText(() => {
      dispatch(setTemplateIsFocusEditor(true));
    });

    yamlInstanceLocal.onDidBlurEditorText(() => {
      dispatch(setTemplateIsFocusEditor(false));
    });

    editor.onDidChangeMarkers((ev) => {
      const [{ path }] = ev;
      const errs = editor
        .getModelMarkers({})
        .filter(({ resource }) => resource.path === path);
      setErrorMarkers(errs);
    });
  }, []);

  useEffect(() => {
    if (!isUpdated) return;
    setIsUpdated(false);
    if (!action) return;
    if (action?.type === ActionsType.nodeCreated) {
      if (yamlInstance.getValue().indexOf(action.nodeId.id) > -1) {
        nodeSelectAndHighlight({ yamlInstance, action });
        dispatch(dropTemplateAction());
      }
    }
  }, [isUpdated, action]);

  useEffect(() => {
    if (!yamlInstance) return;
    yamlInstance.updateOptions({
      readOnly: !isEditorHandlingGranted,
      domReadOnly: !isEditorHandlingGranted,
    });
  }, [yamlInstance, isEditorHandlingGranted]);

  useEffect(() => {
    if (configID) {
      validateProjectYAML({
        id: configID,
        data: debouncedLocalValueForValid,
      });
    } else {
      validateSystemConfigurationTemplate(debouncedLocalValueForValid);
    }
  }, [debouncedLocalValueForValid]);

  useEffect(() => {
    if (projectValidError && 'data' in projectValidError) {
      const dataErrors = projectValidError as IDataValidate;

      // TODO make recursive search in object
    }
  }, [projectValidError, yamlInstance]);

  useEffect(() => {
    if (templateValidError && 'data' in templateValidError) {
      const dataErrors = templateValidError as IDataValidate;

      // TODO make recursive search in object
    }
  }, [templateValidError, yamlInstance]);

  useEffect(() => {
    if (!schema || !yamlInstance) return;
    setDiagnosticsOptions({
      validate: true,
      schemas: generateSchema(schema),
    });
  }, [schema, yamlInstance]);

  useEffect(() => {
    if (!isValidationSchemaLoadingError) return;
    setDiagnosticsOptions({
      validate: true,
      schemas: templateSchema,
    });
  }, [isValidationSchemaLoadingError]);

  useEffect(() => {
    if (!isValidationSchemaLoadingError) return;
    setDiagnosticsOptions({
      validate: true,
      schemas: templateSchema,
    });
  }, [isValidationSchemaLoadingError]);

  useEffect(() => {
    if (
      isValidateProjectYAMLSuccess ||
      isValidateSystemConfigurationTemplateSuccess
    ) {
      setErrorValidMarkers([]);
    }
  }, [
    isValidateProjectYAMLSuccess,
    isValidateSystemConfigurationTemplateSuccess,
  ]);

  useEffect(() => {
    if (!ref.current) return;
    ref.current.childNodes.forEach((el, index) => {
      if (index === ref.current.childNodes.length - 1) {
        el.id = 'EditorView_Area-EditorAreaComponent';
        el.querySelectorAll('.margin')[0].id = 'EditorView_Area-LinesCounter';
        el.querySelectorAll('.monaco-scrollable-element')[0].id =
          'EditorView_Area-EditArea';
        el.querySelectorAll('.view-line').forEach((line) => {
          line.id = 'EditorView_Area-Line_Number';
        });
      } else {
        el.remove();
      }
    });
  }, [ref.current]);

  const problemsList = useMemo(
    () => [...errorMarkers, ...errorValidMarkers],
    [errorMarkers, errorValidMarkers],
  );

  return (
    <Wrapper className={cn(isProblemsBlockOpened && 'opened')}>
      <Resizable
        style={{
          flexGrow: 1,
          border: '1px solid',
          borderColor: theme.palette.divider,
          display: isProblemsBlockExpanded ? 'none' : 'block',
        }}
        handleWrapperClass="handleEditorCode"
        enable={{ bottom: true }}
      >
        <Box ref={ref} sx={{ width: '100%', height: '100%' }} />
        {/* <Prompt
          message="Do you want to leave the page without saving?"
          when={
            oldValue !== value &&
            !sessionStorage.getItem(REDIRECT_URI)
          }
        /> */}
      </Resizable>
      <ProblemsBlock
        expanded={isProblemsBlockExpanded}
        setExpanded={setIsProblemsBlockExpanded}
        opened={isProblemsBlockOpened}
        setOpened={setIsProblemsBlockOpened}
        problemsList={problemsList}
        onClick={handleLineSelect}
      />
    </Wrapper>
  );
};

export default EditArea;
