import React, { useState } from 'react';
import { Box } from '@mui/material';
import { ArrowForwardIos } from '@mui/icons-material';
import { Resizable } from 're-resizable';

interface Props {
  className?: string;
  data?: any;
  selected?: boolean;
  resizeRatio?: number;
  lockAspectRatio?: boolean;
  content: React.JSX.Element;
  defaultMinWidth: number;
  defaultMinHeight: number;
}

interface IHandleBottomRight {
  onMouseOver: () => void;
  onMouseOut: () => void;
}

export function HandleBottomRight(props: IHandleBottomRight) {
  const { onMouseOver, onMouseOut } = props;

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
      }}
    >
      <ArrowForwardIos
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
        sx={{
          width: '8px !important',
          height: '8px !important',
          color: 'error.main',
          transform: 'rotate(45deg)',
        }}
      />
    </div>
  );
}

function BaseFlair(props: Props) {
  const {
    className,
    data,
    selected,
    resizeRatio,
    lockAspectRatio,
    content,
    defaultMinWidth,
    defaultMinHeight,
  } = props;
  const { id, size: propsSize, onResizeStart, onResizeStop } = data || {};
  const { width, height } = propsSize || {};
  const defaultSize = {
    width: width || defaultMinWidth,
    height: height || defaultMinHeight,
  };
  const [size, setSize] = useState(defaultSize);

  const onResize = (e, direction, ref, d) => {
    const newSize = {
      width: Math.max(defaultMinWidth, size.width + d.width),
      height: Math.max(defaultMinHeight, size.height + d.height),
    };
    setSize(newSize);
    onResizeStop(id, newSize);
  };

  const handleResizeStop = () => {
    onResizeStop(id, size);
  };

  return (
    <Box
      component={Resizable}
      className={className}
      minWidth={defaultMinWidth}
      minHeight={defaultMinHeight}
      onResizeStart={onResizeStart}
      onResizeStop={onResize}
      handleWrapperClass="handle"
      handleClasses={{
        bottomRight: 'resize-handle',
      }}
      size={size}
      defaultSize={defaultSize}
      resizeRatio={resizeRatio}
      lockAspectRatio={lockAspectRatio}
      handleComponent={{
        bottomRight: (
          <HandleBottomRight
            onMouseOver={() => onResizeStart(id)}
            onMouseOut={handleResizeStop}
          />
        ),
      }}
      enable={{ bottomRight: selected }}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width,
        height,
        '& svg': {
          maxHeight: '100%',
          maxWidth: '100%',
        },
        '& .resize-handle': {
          width: '10px !important',
          height: '10px !important',
          right: '-6px !important',
          bottom: '-6px !important',
        },
      }}
    >
      {content}
    </Box>
  );
}

BaseFlair.defaultProps = {
  className: '',
  data: {},
  selected: false,
  resizeRatio: 1,
  lockAspectRatio: false,
};

export default BaseFlair;
