/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
// no-check
import React from 'react';
import { Edge, Node } from 'react-flow-renderer';
import {
  Alphinity,
  BufferConcentrate,
  BufferConcentrateV1,
  BufferX,
  Motor,
  FlowMeterSensor,
  ProCaaSo,
  PointComponent,
  Pixer,
  PixerV1,
  Valve,
  Water,
  WaterV1,
} from '../common/Diagram/nodeTypes/library';
import { IComponent } from '../typescript/interfaces/component.interface';
import { IConnector } from '../typescript/interfaces/connector.interface';
import { IPort } from '../typescript/interfaces/ports.interface';
import { ISystem } from '../typescript/interfaces/system.interface';
import { nodesTypes } from '../common/Diagram/nodeTypes/index';
import DiagramComponent from '../common/Diagram/nodeTypes/library/DiagramComponent';
import { apiBaseUrlV1 } from '../env';

const getComponentRenderValue = (
  component,
  type,
  componentLabel,
  componentLink,
  isTemplate,
  componentType,
) => ({
  id: component.id,
  type,
  position: {
    x: Number.isFinite(+component?.render?.position?.x)
      ? +component?.render?.position?.x
      : +component?.position?.x,
    y: Number.isFinite(+component?.render?.position?.y)
      ? +component?.render?.position?.y
      : +component?.position?.y,
  },
  data: {
    label: componentLabel,
    renderProperties: {
      visualsProperties: component?.visual
        ? component?.visuals?.input
        : undefined,
      link: { componentLink, version: componentType?.version },
      flairs: {
        render: component?.visual?.flairs || component?.visual?.flairOverrides,
      },
      ports: {
        render: component?.ports,
        visual: component?.visual?.flairs,
      },
      size: component?.visual?.size,
      isTemplate: isTemplate || !!componentType,
    },
  },
});

export const convertFromDBComponents = (
  components: IComponent[] | Node[],
  system: ISystem,
  componentsStreamState: any,
  currentNameTypes: boolean,
  isTemplate?: boolean,
) => {
  const commonComponents = components
    .map((component: any) => {
      let componentLabel;
      let progress = 0;
      const currentNameTypesLocal = currentNameTypes;

      if (componentsStreamState !== undefined)
        [progress] = componentsStreamState
          .filter((comp) => comp.instruments.length)
          .filter(({ _id }) => component.id === _id)
          .map(({ instruments }) => instruments[0].value);

      if (componentsStreamState !== undefined) {
        const prog = componentsStreamState
          .filter((f) => f.instruments.length)
          .find((c) => c._id === component.id);
        if (prog) {
          progress = +prog.instruments[0].value;
        }
      }

      if (progress < 0) progress = 0;

      const displayProgress = system.name?.toLowerCase().indexOf('solo') === -1;

      const systemVisuals = Object.entries(system.visuals).map(
        ([key, value]) => ({
          name: key,
          // @ts-ignore
          id: value.basedOn.id,
          // @ts-ignore
          version: value.basedOn.version,
          // @ts-ignore
          flairs: value.flairs,
        }),
      );

      const componentType = systemVisuals.find(
        ({ name }) => name === component?.visual?.name,
      );

      if (componentType !== undefined) {
        component = { ...component, type: 'diagramComponent' };
      }

      if (componentType === undefined && !component?.type) {
        component = { ...component, type: 'hidden' };
      }

      if (component.type === 'hidden' && component?.componentType) {
        component = { ...component, type: component?.componentType };
      }

      switch (component?.render?.customType || component?.type) {
        case 'pointComponent':
          componentLabel = <PointComponent />;
          break;
        case 'pixer':
          componentLabel = (
            <Pixer
              key={component.id}
              id={component.id}
              data={component.data}
              currentNameTypes={currentNameTypesLocal}
              isTemplate={isTemplate}
            />
          );
          break;
        case 'pixerV1':
          componentLabel = <PixerV1 id={component.id} data={component.data} />;
          break;
        case 'water':
          componentLabel = (
            <Water
              key={component.id}
              id={component.id}
              data={component.data}
              currentNameTypes={currentNameTypesLocal}
              isTemplate={isTemplate}
            />
          );
          break;
        case 'waterV1':
          componentLabel = (
            <WaterV1
              progress={progress}
              id={component.id}
              data={component.data}
            />
          );
          break;
        case 'bufferX':
          componentLabel = (
            <BufferX
              progress={progress}
              id={component.id}
              data={component.data}
            />
          );
          break;
        case 'bufferConcentrate':
          componentLabel = (
            <BufferConcentrate
              key={component.id}
              id={component.id}
              data={component.data}
              currentNameTypes={currentNameTypesLocal}
              display={displayProgress}
              isTemplate={isTemplate}
            />
          );
          break;
        case 'bufferConcetrate':
          componentLabel = (
            <BufferConcentrate
              key={component.id}
              id={component.id}
              data={component.data}
              currentNameTypes={currentNameTypesLocal}
              display={displayProgress}
            />
          );
          break;
        case 'bufferConcentrateV1':
          componentLabel = (
            <BufferConcentrateV1
              progress={progress}
              id={component.id}
              data={component.data}
            />
          );
          break;
        case 'valve':
          componentLabel = <Valve id={component.id} data={component.data} />;
          break;
        case 'motor':
          componentLabel = <Motor id={component.id} data={component.data} />;
          break;
        case 'flowMeterSensor':
          componentLabel = (
            <FlowMeterSensor id={component.id} data={component.data} />
          );
          break;
        case 'paracloud':
          componentLabel = <ProCaaSo id={component.id} data={component.data} />;
          break;
        case 'secondary_logo':
          componentLabel = (
            <Alphinity id={component.id} data={component.data} />
          );
          break;
        case 'diagramComponent':
          componentLabel = (
            <DiagramComponent
              id={component.id}
              componentName={component.name}
              systemId={system.id}
              {...component}
            />
          );
          break;
        case 'hidden':
          componentLabel = undefined;
          break;
        default:
          componentLabel = <>{component?.data?.label || component.id}</>;
      }

      let type = component?.render?.customType || component?.type;

      if (Object.keys(nodesTypes).indexOf(type) === -1 && type !== 'hidden')
        type = 'input';

      let componentLink;
      if (componentType)
        componentLink = `${apiBaseUrlV1('structure/v1')}/visuals/${
          componentType.id
        }`;

      const componentRender = getComponentRenderValue(
        component,
        type,
        componentLabel,
        componentLink,
        isTemplate,
        componentType,
      );

      return componentRender;
    })
    .filter((v) => v.data.label);

  return commonComponents;
};

export const convertFromDBConnectors = (
  connectors: IConnector[],
  ports: IPort[],
  running: boolean,
) => {
  const allConnectors = connectors
    .filter((connector) => Boolean(connector.port0 && connector.port1))
    .map((connector: any) => {
      const source = ports?.find(
        ({ id }) => connector.port0 === id,
      )?.parentAggregate;
      const target = ports?.find(
        ({ id }) => connector.port1 === id,
      )?.parentAggregate;

      const data = {
        id: connector.id,
        source,
        target,
        // type: connector.render.data.type,
        sourceHandle: connector.render?.data?.sourceHandle || connector.port0,
        targetHandle: connector.render?.data?.targetHandle || connector.port1,
        // animated: connector.render.animated,
        // style: connector.render.style,
        style: { stroke: '#94A3B8', strokeWidth: 5 },
        data: { waypoints: connector?.waypoints },
        markerStart: connector?.render?.arrowHeadType,
        type: 'custom',
      };

      return data;
    });

  return allConnectors;
};

export const calculateConnector = (
  componentSource: IComponent,
  componentTarget: IComponent,
) => {
  let sourceHandle;
  let targetHandle;

  if (componentSource.render.position.x > componentTarget.render.position.x) {
    sourceHandle = `s-bottom-left`;
    targetHandle = `t-right`;
  } else if (
    componentSource.render.position.x <= componentTarget.render.position.x
  ) {
    sourceHandle = `s-bottom-right`;
    targetHandle = `t-left`;
  }

  if (
    componentSource.render.position.y >
    componentTarget.render.position.y + 25
  ) {
    targetHandle += `-bottom`;
  } else if (
    componentSource.render.position.y <
    componentTarget.render.position.y - 25
  ) {
    targetHandle += `-top`;
  } else {
    targetHandle += `-center`;
  }

  return {
    sourceHandle,
    targetHandle,
  };
};

export const calculatePoints = (nodes: any, edges: any) => {
  if (nodes.length > 0) {
    const bufferConcentrate = nodes.filter(
      ({ type }) => type === 'bufferConcentrate',
    )[0];
    const pixer = nodes.filter(({ type }) => type === 'pixer')[0];

    const connector = edges[0];
    const { targetHandle, sourceHandle } = connector;

    if (!pixer.handleBounds.source || !bufferConcentrate.handleBounds.target) {
      return undefined;
    }

    const pixerExhaust = pixer.handleBounds.source.filter(
      ({ id }) => id === sourceHandle,
    )[0];
    const bufferConcentrateExhaust =
      bufferConcentrate.handleBounds.target.filter(
        ({ id }) => id === targetHandle,
      )[0];

    if (!pixerExhaust || !bufferConcentrateExhaust) {
      return undefined;
    }

    const canvasPointPosition = {
      x: targetHandle.endsWith('-left')
        ? bufferConcentrate.position.x.toFixed(0) * 1
        : (bufferConcentrate.position.x + bufferConcentrate.width).toFixed(0) *
          1,
      y:
        (
          bufferConcentrate.position.y +
          (bufferConcentrate.height + pixer.height) / 2
        ).toFixed(0) * 1,
    };

    const newElements = [
      {
        id: `${bufferConcentrate.id}-${pixer.id}`,
        type: 'pointComponent',
        position: {
          x: canvasPointPosition.x,
          y: canvasPointPosition.y,
        },
      },
    ];

    return newElements;
  }

  return undefined;
};

export const calculateLines = (nodes: any, edges: any) => {
  if (nodes.length > 0) {
    const bufferConcentrate = nodes.filter(
      ({ type }) => type === 'bufferConcentrate',
    )[0];
    const pixer = nodes.filter(({ type }) => type === 'pixer')[0];

    const sidePoint = nodes.filter(({ id }) =>
      id.endsWith(`${bufferConcentrate.id}-${pixer.id}`),
    )[0];

    const connector = edges[0];
    const { targetHandle, sourceHandle } = connector;

    if (!sidePoint) {
      return undefined;
    }

    const newElements = [
      {
        id: `${connector.id}-1`,
        source: pixer.id,
        target: `${bufferConcentrate.id}-${pixer.id}`,
        sourceHandle,
        targetHandle: 't-center',
        animated: false,
        style: { stroke: '#94A3B8', strokeWidth: 5 },
        type: 'custom',
      },
      {
        id: `${connector.id}-2`,
        source: `${bufferConcentrate.id}-${pixer.id}`,
        target: bufferConcentrate.id,
        sourceHandle: 's-center',
        targetHandle,
        animated: false,
        style: { stroke: '#94A3B8', strokeWidth: 5 },
        type: 'custom',
      },
    ];

    return newElements;
  }
  return undefined;
};

const getPositionValue = (
  edgesForNodeTarget,
  edgesForNodeSource,
  startingPoint,
  idx,
  nodes,
) => {
  let pos;
  if (edgesForNodeTarget.length > 0 && edgesForNodeSource.length === 0) {
    pos = {
      x: (startingPoint.x || 0) + 150 * idx,
      y: (startingPoint.y || 0) + 850,
    };
  } else if (edgesForNodeTarget.length === 0 && edgesForNodeSource.length > 0) {
    pos = {
      x: startingPoint.x || 0,
      y: startingPoint.y || 0,
    };
  } else if (
    edgesForNodeTarget.length === 0 &&
    edgesForNodeSource.length === 0
  ) {
    pos = {
      x: (startingPoint.x || 0) - 150 * nodes.length - (idx + 1),
      y: (startingPoint.y || 0) + 0,
    };
  } else {
    pos = {
      x: (startingPoint.x || 0) + 150 * idx,
      y: (startingPoint.y || 0) + 550,
    };
  }

  return pos;
};

const getNodesResult = (name, value, visualObject, componentLink) => ({
  id: name,
  type:
    value?.visuals || value?.visual ? 'diagramComponent' : value?.componentType,
  position: {
    x: value?.render && value?.render[0],
    y: value?.render && value?.render[1],
  },
  data: {
    label: name,
    renderProperties: {
      visualsProperties: value.visuals ? value.visuals.input : undefined,
      link: visualObject
        ? {
            ...visualObject,
            componentLink,
          }
        : { componentLink },
      flairs: {
        render: value?.visual?.flairs || value?.visuals?.flairs,
      },
      ports: {
        render: value?.ports,
        visual: value?.visual?.flairs || value?.visuals?.flairs,
      },
      size: value?.visual?.size,
    },
  },
});

const getComponentLink = (componentVisuals, visuals, valVisuals, value) => {
  let componentLink;
  if (componentVisuals && visuals) {
    const componentLinkInternal = visuals[componentVisuals];
    if (componentLinkInternal) componentLink = componentLinkInternal.$ref;
  }

  let visualObject;

  if (valVisuals && !componentLink) {
    visualObject = valVisuals[value.visual?.$ref.replace(/#\/visuals\//gm, '')];
    if (visualObject)
      componentLink = `${apiBaseUrlV1('structure/v1')}/visuals/${
        visualObject.id
      }`;
  }
  return { componentLink, visualObject };
};

const getNodesValue = (valNodes, visuals, valVisuals) =>
  valNodes
    ? Object.entries(valNodes).map(([name, value]: any) => {
        let componentVisuals;
        if (value?.visuals && typeof value?.visuals?.$ref === 'string')
          componentVisuals = value?.visuals?.$ref.replace(/#\/visuals\//gm, '');

        const { componentLink, visualObject } = getComponentLink(
          componentVisuals,
          visuals,
          valVisuals,
          value,
        );

        const node = getNodesResult(name, value, visualObject, componentLink);

        return node;
      })
    : [];

const getEdgesValue = (valEdges, nodes, handleWaypointsChange) =>
  valEdges
    ? Object.entries(valEdges).map(([name, value]: any) => {
        if (value?.ports) {
          const [sourceStr, targetStr] = value?.ports || [];

          const source = nodes.filter(
            ({ id }) => sourceStr.$ref.indexOf(`/${id}/`) > -1,
          )[0]?.id;
          const target = nodes.filter(
            ({ id }) => targetStr.$ref.indexOf(`/${id}/`) > -1,
          )[0]?.id;

          const sourceHandle = sourceStr.$ref.substring(
            sourceStr.$ref.lastIndexOf('ports/') + 'ports/'.length,
            sourceStr.$ref.length,
          );
          const targetHandle = targetStr.$ref.substring(
            targetStr.$ref.lastIndexOf('ports/') + 'ports/'.length,
            targetStr.$ref.length,
          );

          return {
            id: name,
            source,
            target,
            sourceHandle,
            targetHandle,
            data: { waypoints: value?.waypoints, handleWaypointsChange },
          };
        }
        return {
          id: name,
          source: undefined,
          target: undefined,
          sourceHandle: undefined,
          targetHandle: undefined,
        };
      })
    : [];

export const parseValue = (val, initPosition, handleWaypointsChange?) => {
  if (!val) return { nodes: [], edges: [] };
  const nodesProperty = Object.keys(val).find(
    (key) => key.indexOf('components') > -1,
  );
  const edgesProperty = Object.keys(val).find(
    (key) => key.indexOf('connectors') > -1,
  );
  const { visuals } = val;
  const valNodes = val?.[nodesProperty];
  const valEdges = val?.[edgesProperty];
  const configName: string = val?.name || '';
  const configDescription: string = val?.description || '';
  const valVisuals = val?.visuals;

  const nodes = getNodesValue(valNodes, visuals, valVisuals);

  const edges: Edge[] = getEdgesValue(valEdges, nodes, handleWaypointsChange);

  const componentsHavePosition = nodes.filter(
    ({ position }) => position.x !== undefined && position.y !== undefined,
  );

  const componentsPosition =
    componentsHavePosition.length > 0
      ? {
          x: +componentsHavePosition[0].position.x,
          y: +componentsHavePosition[0].position.y,
        }
      : { x: undefined, y: undefined };

  const startingPoint =
    initPosition.x === undefined
      ? componentsPosition
      : {
          x: +initPosition?.x,
          y: +initPosition?.y,
        };

  const waypoints = edges
    .map((edge) => {
      const sourceNode = nodes?.find(({ id }) => id === edge.source);
      if (!sourceNode) return undefined;
      const positionOfSource = {
        x: sourceNode.position.x,
        y: sourceNode.position.y,
      };

      return edge?.data?.waypoints?.map((position, idx) => ({
        id: `edgeDot_${edge.id}_${idx}`,
        type: 'edgeDot',
        position: {
          x: positionOfSource.x + position?.x,
          y: positionOfSource.y + position?.y,
        },
        data: { isHidden: true },
        width: 18,
        height: 18,
      }));
    })
    .flat()
    .filter((n) => Boolean(n));

  const positionWithPosition =
    nodes.length > 0
      ? nodes.map((node, idx) => {
          if (node?.position?.x) {
            return node;
          }
          let edgesForNodeTarget = [];
          let edgesForNodeSource = [];
          if (edges?.length > 0) {
            edgesForNodeTarget = edges?.filter(
              ({ target }) => node.id === target,
            );
            edgesForNodeSource = edges?.filter(
              ({ source }) => node.id === source,
            );
          }

          const pos = getPositionValue(
            edgesForNodeTarget,
            edgesForNodeSource,
            startingPoint,
            idx,
            nodes,
          );

          return { ...node, position: pos };
        })
      : nodes;

  return {
    nodes: [...positionWithPosition, ...waypoints],
    edges,
    name: configName,
    description: configDescription,
    initialPosition: startingPoint,
    visuals,
  };
};

export const calculateHandles = (sNode, sHandles, tNode, tHandles) => {
  const lenArray = sHandles.map(({ id: sId, x: sx, y: sy }) =>
    tHandles.map(({ id: tId, x: tx, y: ty }) => ({
      sId,
      tId,
      len:
        ((tNode.position.x + tx - sNode.position.x - sx) ** 2 +
          (tNode.position.y + ty - sNode.position.y - sy) ** 2) **
        0.5,
    })),
  );

  const [minLen] = lenArray
    .flat(2)
    .filter(({ sId, tId }) => sId && tId)
    .sort((a, b) => (a?.len > b?.len ? 1 : -1));

  return {
    sourceHandle: minLen?.sId || 't-left-top',
    targetHandle: minLen?.tId || 't-left-top',
  };
};

export const parseVisualAssetToNode = ({
  svgInfo,
  jsonData,
}: {
  svgInfo: string;
  jsonData: any;
}) => {
  const value = {
    visual: { size: jsonData?.size },
    render: [0, 0],
    ports: {},
  };

  const visualObject = {
    renderProperties: {
      flairs: {},
      version: 0,
    },
  };

  const node = getNodesResult(
    'SVG_VISUAL_COMPONENT',
    value,
    visualObject,
    svgInfo,
  );

  return [node].map((n) => ({
    ...n,
    dragHandle: 'disable',
    draggable: false,
    selectable: false,
  }));
};

export const parseVisualflairsToNode = (objFlairs: any) => {
  if (!objFlairs) return undefined;

  const nodes = [];
  const flairs: any[] = Object.values(objFlairs || {});
  const keys: any[] = Object.keys(objFlairs || {});

  if (Array.isArray(flairs)) {
    flairs
      .filter((flair) => flair?.type)
      .forEach((flair, index) => {
        const { size, placement, type, ...rest } = flair;

        const width = size?.width || null;
        const height = size?.height || null;

        const node: any = {
          id: keys[index],
          type,
          data: {
            ...rest,
            size: {
              height,
              width,
            },
          },
          position: {
            x: placement?.x || 0,
            y: placement?.y || 0,
          },
        };

        if (typeof height === 'number') {
          node.width = width;
        }

        if (typeof height === 'number') {
          node.height = height;
        }

        nodes.push(node);
      });
  }

  return nodes;
};
