import { rootApi } from '..';
import { apiBaseUrlV1 } from '../../../env';
import {
  IFnsPackage,
  IFnsProject,
} from '../../../typescript/interfaces/fnsProject.interface';

const BASE_URL = apiBaseUrlV1('ubiety/v1');

const extendedApi = rootApi.injectEndpoints({
  endpoints: (builder) => ({
    getFnsProjects: builder.query<IFnsProject[], void>({
      query: () => `${BASE_URL}/fnsProjects`,
      providesTags: ['FnsProjects'],
      transformResponse: (res: IFnsProject[]) =>
        [...res].sort((a, b) => {
          if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
          if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
          return 0;
        }),
    }),
    createFnsProject: builder.mutation<
      any,
      { name: string; description: string }
    >({
      query: (data) => ({
        url: `${BASE_URL}/fnsProjects`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['FnsProjects'],
    }),
    deleteFnsProject: builder.mutation<null, string>({
      query: (id) => ({
        url: `${BASE_URL}/fnsProjects/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['FnsProjects'],
    }),
    getFnsPackagesByProjectId: builder.query<IFnsPackage[], string>({
      query: (id: string) => `${BASE_URL}/fnsPackages?projectID=${id}`,
      providesTags: ['FnsPackages'],
    }),
    uploadFnsPackage: builder.mutation<
      { message: string },
      { formData: FormData; id: string }
    >({
      query: ({ formData, id }) => ({
        url: `${BASE_URL}/fnsPackages?projectID=${id}`,
        method: 'POST',
        body: formData,
      }),
      invalidatesTags: ['FnsPackages'],
    }),
    deleteFnsPackage: builder.mutation<null, string>({
      query: (id) => ({
        url: `${BASE_URL}/fnsPackages/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['FnsPackages'],
    }),
    deployFnsPackage: builder.mutation<
      { success: boolean; message: string },
      { id: string; data: any }
    >({
      query: ({ id, data }) => ({
        url: `${BASE_URL}/fnsDeployments?packageID=${id}`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['FnsPackages', 'FnsDeployments'],
    }),
    requestFnsDeploymentTermination: builder.mutation<
      { success: boolean; message: string },
      { id: string; deleteDatabase: boolean }
    >({
      query: ({ id, deleteDatabase }) => ({
        url: `${BASE_URL}/fnsDeployments/${id}/requestTermination`,
        method: 'PATCH',
        body: { delete_database: deleteDatabase },
      }),
      invalidatesTags: ['FnsPackages', 'FnsDeployments'],
    }),
    getFnsDeploymentsByProjectId: builder.query<any[], string>({
      query: (id) => `${BASE_URL}/fnsDeployments?projectID=${id}`,
      providesTags: ['FnsDeployments'],
    }),
  }),
});

export const {
  useGetFnsProjectsQuery,
  useCreateFnsProjectMutation,
  useDeleteFnsProjectMutation,
  useGetFnsPackagesByProjectIdQuery,
  useUploadFnsPackageMutation,
  useDeleteFnsPackageMutation,
  useDeployFnsPackageMutation,
  useRequestFnsDeploymentTerminationMutation,
  useGetFnsDeploymentsByProjectIdQuery,
} = extendedApi;
